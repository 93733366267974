import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { ShowThen } from "../../../shared-module/components/show-then/show-then.component";
import { AppSettings } from "../../../app-setting";
import { EventBroadcasterService, SessionStateChangedEventArgs } from "../../../shared-module/events/event-broadcaster.service";
import { NavigationHelper } from "../../../shared-module/helpers/navigation-helper";
import { useNavigate } from "react-router-dom";
import { SessionService } from "shared-module/services/session.service";
import { ApplicationTypeNames } from "shared-module/models/common.models";

function AutoExpireNotificationGuestComponent() {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState<boolean>(false);
  const [remainingTime, setRemainingTime] = useState<number>(0);

  function getRemainingTimeText(): string {
    return `less than ${remainingTime && remainingTime <= 60 ? "one minute" : "two minutes"}`;
  }

  function sessionStateChanged(event: SessionStateChangedEventArgs) {
    console.log('event: ', event);
    if(event.remainingTimeInSeconds == -999) { return; }

    const activate = !event.isAuthenticated &&
      event.remainingTimeInSeconds <= AppSettings.autoLogoutNotificationWindowInSeconds;

    setIsActive(activate);
    setRemainingTime(event.remainingTimeInSeconds);

    if(event.remainingTimeInSeconds <= 0) {
      expireSession();
      NavigationHelper.gotoNbarHome(navigate);
    }
    
  }

  useEffect(() => {
    EventBroadcasterService.RegisterForGuestSessionExpirationEvent(sessionStateChanged);
    return () => {
      EventBroadcasterService.UnregisterForGuestSessionExpirationEvent(sessionStateChanged);
    }
  }, []);

  function resumeSession() {
    setIsActive(false);
    return SessionService.slideGuestSession(Number(sessionStorage.getItem("guestSessionId")));
  }

  function expireSession() {
    console.log('expire session: ');
    setIsActive(false);
    var id = sessionStorage.getItem("guestSessionId");
    sessionStorage.removeItem("isGuestUser");
    sessionStorage.removeItem("guestSessionId");
    SessionService.expireGuestSession(Number(id))
  }

  return (
    <>
      <ShowThen when={isActive}>
        <div className="bds-auto-expire-session-banner">
          <div className="container bds-container p-2">
            <div className="row">
              <div className="col-9 d-flex">
                <div className="align-self-center">
                  <span className="heading">Auto Expiration Notification</span>
                  {" - "}
                  <span className="message">Your session will exipire in { getRemainingTimeText() }.</span>
                </div>
              </div>
              <div className="col-3">
                <div className="row">
                    <button type="button" className="btn btn-sm bds-btn-primary w-100" onClick={resumeSession}>Resume Session</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ShowThen>
    </>
  )
}

export const AutoExpireGuestComponent = observer(AutoExpireNotificationGuestComponent)
