import { toast } from 'react-toastify';
import { ReportHandler } from 'web-vitals';
import AlertModal from "../../shared-module/components/alert-modal/alert-modal.component";
import PreviewModal from "../../shared-module/components/file-preview-modal/file-preview-modal.component";
import ConfirmModal from "../components/confirm-modal/confirm-modal";
import loadingOverlay from '../components/loading-overlay/loading-overlay';
import { BdsYupValidationError } from '../hooks/use-form-validation/use-form-validation.hook';
import * as enums from './enums'
import * as Yup from "yup";
import { AppSettings } from 'app-setting';

export interface SignatureStamp {
  signatureOn: string,
  signature: string
}

export interface BooleanCondition<T> {
  condition: boolean,
  value: T
}

export class Utility {
  static caseInsensitiveCompare(left: string, right: string) {
    return left.toUpperCase() === right.toUpperCase();
  }

  public static ReportWebVitals(onPerfEntry?: ReportHandler): void {
    if (onPerfEntry && onPerfEntry instanceof Function) {
      import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
        getCLS(onPerfEntry);
        getFID(onPerfEntry);
        getFCP(onPerfEntry);
        getLCP(onPerfEntry);
        getTTFB(onPerfEntry);
      });
    }
  }

  public static toDate(value: string): Date {
    if (value) {
      return new Date(value);
    }
    return null;
  }

  public static toHtmlDateString(value: Date, defaultValue: string = null): string {
    if (value) {
      var yearPart = ("0000" + value.getFullYear()).slice(-4);
      var monthPart = ("00" + (value.getMonth() + 1)).slice(-2);
      var dayPart = ("00" + value.getDate()).slice(-2);
      return `${yearPart}-${monthPart}-${dayPart}`;
    }
    return defaultValue;
  }

  public static fromHtmlDateString(value: string, defaultValue: Date = null): Date {
    if (value && this.isHtmlDateStringValid(value)) {
      var yearPart = (+value.substring(0, 4));
      var monthPart = (+value.substring(5, 7)) - 1;
      var dayPart = (+value.substring(8, 10));
      var date = new Date(yearPart, monthPart, dayPart);
      return date;
    }
    return defaultValue;
  }

  public static toDateString_US_en(value: string, defaultValue: string): string {
    if (value) {
      var date = new Date(value);
      var yearPart = ("0000" + date.getFullYear()).slice(-4);
      var monthPart = ("00" + (date.getMonth() + 1)).slice(-2);
      var dayPart = ("00" + date.getDate()).slice(-2);
      return `${monthPart}/${dayPart}/${yearPart}`;
    }

    return defaultValue;
  }

  public static getToday(): Date {
    var date = new Date();
    var today = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    return today;
  }

  public static getMinimumAge(): Date {
    var minAgeRestriction = Number(AppSettings.ageDateRangeRestriction);
    var today = new Date();
    var minDate = new Date((today.getFullYear() - minAgeRestriction), today.getMonth(), today.getDate());
    return minDate
  }

  public static isMinor(birthdate: string): boolean {
    const bd = this.toDate(birthdate);
    const today = this.getToday();
    return today.getTime() - bd.getTime() < 18
  }

  public static defaultIfNull<T>(value: T, defaultValue: T): T {
    return (value == null || value == undefined) ? defaultValue : value;
  }

  public static toTriStateString<T>(value: boolean, ifTrue: string, ifFalse: string, ifNotBoolean: string): string {
    return (value === true || value === false) ? (value == true ? ifTrue : ifFalse) : ifNotBoolean;
  }

  public static numberToString(value: number, defaultValue: string): string {
    return (value == null || value == undefined) ? defaultValue : value.toString();
  }

  public static stringToNumber(value: string, defaultValue: number): number {
    return (value == null || value == undefined || value == "" || isNaN(+value)) ? defaultValue : (+value);
  }

  public static numberToBool(value: number, defaultValue: boolean): boolean {
    return (value == null || value == undefined || isNaN(+value)) ? defaultValue : (!!value);
  }

  public static toastError(Message: string): void {
    toast(Message, { closeButton: true, autoClose: 2000, delay: 0, position: 'top-right' });
  }

  public static isGuid(value: string): boolean {
    var regEx = new RegExp(/^[0-9a-fA-Z]{8}-[0-9a-fA-Z]{4}-[0-9a-fA-Z]{4}-[0-9a-fA-Z]{4}-[0-9a-fA-Z]{12}$/i);
    return regEx.test(value);
  }

  public static isHtmlDateStringValid(dateString: string): boolean {
    return new RegExp(/^[1-9][0-9]{3}-\d{2}-\d{2}$/).test(dateString);
  }

  public static mergeZipCodeParts(zipcode: string, zipPlus4: string, defaultValue: string): string {
    return !zipcode ? defaultValue : zipPlus4 ? `${zipcode}-${zipPlus4}` : zipcode;
  }

  public static alert(title: React.ReactNode, message: React.ReactNode, callback: () => Promise<void> = null): void {
    AlertModal.showModal({ header: title, message: message, okButtonText: "Ok", callback });
  }

  public static confirm(title: React.ReactNode, message: React.ReactNode, callback: (boolean) => Promise<void>): void {
    ConfirmModal.showModal({ header: title, message: message, yesButtonText: "Yes", noButtonText: "No", callback });
  }

  public static preview(title: React.ReactNode, message: React.ReactNode, callback: () => Promise<void> = null): void {
    PreviewModal.showModal({ header: title, message: message, okButtonText: "Close", callback });
  }

  private static overlayTimer = null;

  public static showLoadingOverlay(): void {
    // is there a hide request pending?
    // if yes remove it, we do not need it
    if (Utility.overlayTimer) {
      clearTimeout(Utility.overlayTimer);
      Utility.overlayTimer = null;
    }

    loadingOverlay.show();
  }

  public static hideLoadingOverlay(debounce: number = 300): void {
    // is there a hide request pending?
    // if yes remove it, we would debounce it
    if (Utility.overlayTimer) {
      clearTimeout(Utility.overlayTimer);
      Utility.overlayTimer = null;
    }

    Utility.overlayTimer = setTimeout(() => {
      loadingOverlay.hide();
    }, debounce);
  }

  public static getBooleanToTypedResult<T>(conditions: BooleanCondition<T>[], defaultValue: T)
    : T {
    var found = conditions.find((item) => {
      return item.condition;
    });
    return found ? found.value : defaultValue;
  }

  public static buildYupErrorMessage(key: string, message: string): Yup.CreateErrorOptions {
    return { message: { key: key, message: message } as Yup.Message<BdsYupValidationError> };
  }

  public static getApplicantStamp(name: string, applicationRefId: string): SignatureStamp {
    return Utility.createSignatureCapture(name, applicationRefId);
  }

  public static getLegalRepresentativeStamp(name: string, applicationRefId: string): SignatureStamp {
    return Utility.createSignatureCapture(name, applicationRefId);
  }

  public static getRadioGroupYesNoOptions(addNA : boolean = false) {
    var options = [
      {text: "Yes", value: enums.YesNoNARadioButtonGroupOptions.Yes},
      {text: "No", value: enums.YesNoNARadioButtonGroupOptions.No},
    ]

    if(addNA) {
      options.push({text: "Not Applicable", value: enums.YesNoNARadioButtonGroupOptions.NotApplicable})
    }

    return options;
  }

  public static getStudentStatusRadioOptions(addNA : boolean = false) {   
    var options = [
      {text: "Full day 5 days/week", value: enums.StudentStatusRadioButtonGroupOptions.FullTime},
      {text: "Part time - less than 5 days per week", value: enums.StudentStatusRadioButtonGroupOptions.PartTime}
    ]

    if(addNA) {
      options.push({text: "Not Applicable", value: enums.StudentStatusRadioButtonGroupOptions.NotApplicable})
    }
    return options;
  }

  public static getNbarDefinitions() {
    var options = [
      { key: "BDS", value: "Bureau of Disabilities Services as created under IC 12-11-1.1-1." },
      { key: "ISDH", value: "Indiana State Department of Health" },
      { key: "Eligible", value: "an administrative status in which it has been determined and documented by an employee of DDRS that an individual meets certain criteria." },
      { key: "Natural supports", value: "supports that are already in existence and not artificially created that enhance skill acquisition, promote independence, and foster meaningful relationships between persons with a developmental disability and members of a community such as those provided by family; friends, and neighbors without formal reimbursement." },
      { key: "Supervised Group Living (SGL)", value: "a residential setting for four (4) to eight (8) individuals living together with twenty-four (24) hour supervision and funded by Medicaid, also referred to as group homes." },
    ]
    return options;
  }

  private static createSignatureCapture(name, applicationRefId): SignatureStamp {
    var tzoffset = (new Date()).getTimezoneOffset() * 60000;
    var sigStamp = Utility.substr(new Date(Date.now() - tzoffset).toISOString(), 0, 19);
    var signatureString = sigStamp + (name || "") + applicationRefId;
    return {
      signatureOn: sigStamp,
      signature: signatureString
    };
  }

  private static substr(value: string, index: number, length: number): string {
    return !value ? value : (value.length <= length ? value : value.substring(index, length));
  }

}