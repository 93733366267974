import { useNavigate, useParams } from "react-router-dom";
import { Card } from "../../../shared-module/components/card/card.component";
import { NavigationHelper } from "../../../shared-module/helpers/navigation-helper";
import { PageTitleSetter } from "../../extensions/page-title-setter/page-title-setter.component";
import { ApplicationProgressBar } from "../../extensions/application-progress-bar/application-progress-bar.component";
import { useEffect, useState } from "react";
import { ApplicationResource } from "../../../shared-module/api-resources/application.resource";
import { GatewayOapApi } from "../../../shared-module/models/gateway-oap-api.models";
import { ShowThen } from "../../../shared-module/components/show-then/show-then.component";
import { Utility } from "../../../shared-module/helpers/utility";
import { ApiConfig } from "shared-module/services/api-config";
import { IsAuthenticated } from "shared-module/components/is-authenticated/is-authenticated";
import { ApplicationExpirationError, GatewayNetworkError } from "shared-module/models/common.models";

function ApplicantionSummary() {
  const { id: applicationRefId } = useParams();
  const navigate = useNavigate();
  const applicationService = new ApplicationResource(ApiConfig);
  const [application, setApplication] = useState<GatewayOapApi.ApplicationModel>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {

    if (!hasApplicationRefId()) {
      NavigationHelper.gotoHome(navigate);
      return;
    }

    setIsLoading(true);
    Utility.showLoadingOverlay();

    Promise.all([
      applicationService.getApplication(applicationRefId)
    ])
      .then((response) => {
        const application = response[0].record;
        setApplication(application);
        sessionStorage.setItem('fromSummary', 'true');
        return true;
      })
      .catch((ex) => { 
        if(ex instanceof ApplicationExpirationError) {
          Utility.alert("Application Timeout", ex.errorMessage);
        }
        if (!(ex instanceof GatewayNetworkError)) {
          Utility.alert("Error Saving", ex.errorMessage);
        }
        NavigationHelper.gotoHome(navigate);
      })
      .finally(() => {
        setIsLoading(false);
        Utility.hideLoadingOverlay();
      });

  }, []);

  function hasApplicationRefId() {
    return !!applicationRefId;
  }

  function getApplicationPrimaryPhoneNumber(application: GatewayOapApi.ApplicationModel, defaultValue: string) {
    var primary = 'N/A';
    if (application?.homePhone?.isPrimary) {
      primary = 'Home';
    } else if (application?.mobilePhone?.isPrimary) {
      primary = 'Mobile';
    } if (application?.workPhone?.isPrimary) {
      primary = "Work";
    }
    return primary;
  }

  function getLegalRepresentativePrimaryPhoneNumber(legalRep: GatewayOapApi.LegalRepresentativeModel, defaultValue: string) {
    var primary = 'N/A';
    if (legalRep?.homePhone?.isPrimary) {
      primary = 'Home';
    } else if (legalRep?.mobilePhone?.isPrimary) {
      primary = 'Mobile';
    } if (legalRep?.workPhone?.isPrimary) {
      primary = "Work";
    }
    return primary;
  }

  function formatPhoneNumber(phoneNumber: string, defaultValue: string): string {
    if (phoneNumber && phoneNumber.length >= 10) {
      return `(${phoneNumber.substring(0, 3)}) ${phoneNumber.substring(3, 6)}-${phoneNumber.substring(6)}`;
    }
    return "N/A";
  }

  function formatAddress(address: GatewayOapApi.AddressModel) {
    if (!address || !address.streetAddress1) {
      return "N/A";
    }

    return `${address.streetAddress1} ${address.streetAddress2} ${address.city}
      ${address.stateName} ${Utility.mergeZipCodeParts(address.zipCode, address.zipPlus4, "")}`;
  }

  return (
    <>
      <PageTitleSetter step={6} totalSteps={8}></PageTitleSetter>
      <div className="mt-4">
        <ApplicationProgressBar step={6} totalSteps={8}></ApplicationProgressBar>
      </div>

      <ShowThen when={application != null}>
        <div className="mt-4">
          <Card>
            <div className="row">
              <div className="col-8">
                <div className="bds-summary-section-heading">General Information</div>
              </div>
              <div className="col-4 text-end">
                <button type="button" disabled={isLoading}
                  onClick={() => NavigationHelper.gotoApplicationInformation(navigate, applicationRefId)}
                  className="btn bds-btn-primary">Edit Section</button>
              </div>
            </div>
            <hr className="bds-divider mt-3" />
            <div className="row">
              <div className="col-3 mt-4">
                <label className="bds-summary-label">Application Type:</label>
              </div>
              <div className="col-9 mt-4">
                {application?.selfApply ? "I am applying for myself." : "I am helping someone who is applying."}
              </div>
              <ShowThen when={application && !application?.selfApply}>
                <div className="col-6 col-md-3 mt-3">
                  <label className="bds-summary-label">Relationship:</label>
                </div>
                <div className="col-6 col-md-3 mt-3">
                  {application?.entryRelationTypeName}
                </div>
              </ShowThen>
              <div className="col-6 col-md-3 mt-3">
                <label className="bds-summary-label">Name:</label>
              </div>
              <div className="col-6 col-md-3 mt-3">
                <ShowThen when={application?.firstName}>
                  {application?.firstName} {" "}
                </ShowThen>
                <ShowThen when={application?.middleName}>
                  {application?.middleName} {" "}
                </ShowThen>
                <ShowThen when={application?.lastName}>
                  {application?.lastName} {" "}
                </ShowThen>
                <ShowThen when={application?.suffixTypeName}>
                  {application?.suffixTypeName}
                </ShowThen>
              </div>
              <div className="col-6 col-md-3 mt-3">
                <label className="bds-summary-label">SSN/ITIN:</label>
              </div>
              <div className="col-6 col-md-3 mt-3">
                {application?.isItin ? "ITIN" : "SSN"}
                {" "}
                {application?.ssn}
              </div>
              <div className="col-6 col-md-3 mt-3">
                <label className="bds-summary-label">Date of Birth:</label>
              </div>
              <div className="col-6 col-md-3 mt-3">
                {Utility.toDateString_US_en(application?.dateOfBirth, "")}
              </div>
              <div className="col-6 col-md-3 mt-3">
                <label className="bds-summary-label">Medicaid:</label>
              </div>
              <div className="col-6 col-md-3 mt-3">
                {application?.hasMedicaid ? "YES" : "NO"}
              </div>
              <ShowThen when={application?.hasMedicaid}>
                <div className="col-6 col-md-3 mt-3">
                  <label className="bds-summary-label">Medicaid Number:</label>
                </div>
                <div className="col-6 col-md-3 mt-3">
                  {application?.medicaidRid}
                </div>
              </ShowThen>
            </div>
          </Card>
        </div>

        <div className="mt-4">
          <Card>
            <div className="row">
              <div className="col-8">
                <div className="bds-summary-section-heading">Applicant Information</div>
              </div>
              <div className="col-4 text-end">
                <button type="button" disabled={isLoading}
                  onClick={() => NavigationHelper.gotoApplicantInformation(navigate, applicationRefId)}
                  className="btn bds-btn-primary">Edit Section</button>
              </div>
            </div>
            <hr className="bds-divider mt-3" />
            <div className="row">
              <div className="col-6 col-md-3 mt-3 mt-md-4">
                <label className="bds-summary-label">Living Arrangement:</label>
              </div>
              <div className="col-6 col-md-3 mt-3 mt-md-4">
                {Utility.defaultIfNull(application?.livingArrangementTypeName, "N/A")}
              </div>
              <div className="col-6 col-md-3 mt-3 mt-md-4">
                <label className="bds-summary-label">Home:</label>
              </div>
              <div className="col-6 col-md-3 mt-3 mt-md-4">
                {formatPhoneNumber(application?.homePhone?.phoneNumber, "N/A")}
              </div>
              <div className="col-6 col-md-3 mt-3">
                <label className="bds-summary-label">Primary Phone:</label>
              </div>
              <div className="col-6 col-md-3 mt-3">
                {getApplicationPrimaryPhoneNumber(application, "N/A")}
              </div>
              <div className="col-6 col-md-3 mt-3">
                <label className="bds-summary-label">Mobile:</label>
              </div>
              <div className="col-6 col-md-3 mt-3">
                {formatPhoneNumber(application?.mobilePhone?.phoneNumber, "N/A")}
              </div>
              <div className="col-6 col-md-3 mt-3">
                <label className="bds-summary-label">Email Address:</label>
              </div>
              <div className="col-6 col-md-3 mt-3">
                {Utility.defaultIfNull(application?.emailAddress, "N/A")}
              </div>
              <div className="col-6 col-md-3 mt-3">
                <label className="bds-summary-label">Work:</label>
              </div>
              <div className="col-6 col-md-3 mt-3">
                {formatPhoneNumber(application?.workPhone?.phoneNumber, "N/A")}
              </div>
              <ShowThen when={application?.mailingAddress && !application?.mailingAddress.isInternational}>
                <div className="col-6 col-md-3 mt-3">
                  <label className="bds-summary-label">Mailing Address:</label>
                </div>
                <div className="col-6 col-md-3 mt-3">
                  {formatAddress(application?.mailingAddress)}
                </div>
              </ShowThen>
              <ShowThen when={application?.mailingAddress && !application?.mailingAddress.isInternational}>
                <div className="col-6 col-md-3 mt-3">
                  <label className="bds-summary-label">Mailing County:</label>
                </div>
                <div className="col-6 col-md-3 mt-3">
                  {Utility.defaultIfNull(application?.mailingAddress?.countyName, "N/A")}
                </div>
              </ShowThen>
              <ShowThen when={application?.hasDifferentCurrentAddress &&
                application?.currentAddress &&
                !application?.currentAddress?.isInternational}>
                <div className="col-6 col-md-3 mt-3">
                  <label className="bds-summary-label">Current Address:</label>
                </div>
                <div className="col-6 col-md-3 mt-3">
                  {formatAddress(application?.currentAddress)}
                </div>
              </ShowThen>
              <ShowThen when={!application?.hasDifferentCurrentAddress  || false}>
                <div className="col-6 col-md-3 mt-3">
                  <label className="bds-summary-label">Current Address:</label>
                </div>
                <div className="col-6 col-md-3 mt-3">
                  Same as mailing 
                </div>
              </ShowThen>

              <ShowThen when={application?.hasDifferentCurrentAddress &&
                application?.currentAddress &&
                !application?.currentAddress?.isInternational}>
                <div className="col-6 col-md-3 mt-3">
                  <label className="bds-summary-label">Current County:</label>
                </div>
                <div className="col-6 col-md-3 mt-3">
                  {Utility.defaultIfNull(application?.currentAddress?.countyName, "N/A")}
                </div>
              </ShowThen>
              <ShowThen when={application?.mailingAddress &&
                application?.mailingAddress?.isInternational}>
                <div className="col-6 col-md-3 mt-3">
                  <label className="bds-summary-label">International Address:</label>
                </div>
                <div className="col-6 col-md-3 mt-3">
                  {Utility.defaultIfNull(application?.mailingAddress?.internationalAddress, "N/A")}
                </div>
              </ShowThen>
              <ShowThen when={application?.hasDifferentCurrentAddress &&
                application?.currentAddress &&
                application?.currentAddress?.isInternational}>
                <div className="col-6 col-md-3 mt-3">
                  <label className="bds-summary-label">Current International Address:</label>
                </div>
                <div className="col-6 col-md-3 mt-3">
                  {Utility.defaultIfNull(application?.currentAddress?.internationalAddress, "N/A")}
                </div>
              </ShowThen>
            </div>
          </Card>
        </div>

        <div className="mt-4">
          <Card>
            <div className="row">
              <div className="col-8">
                <div className="bds-summary-section-heading">Disability Information</div>
              </div>
              <div className="col-4 text-end">
                <button type="button" disabled={isLoading}
                  onClick={() => NavigationHelper.gotoApplicantInformation(navigate, applicationRefId)}
                  className="btn bds-btn-primary">Edit Section</button>
              </div>
            </div>
            <hr className="bds-divider mt-3" />
            <div className="row">
              <div className="col-6 col-md-3 mt-3 mt-md-4">
                <label className="bds-summary-label">Age of Diagnosis/Disability:</label>
              </div>
              <div className="col-6 col-md-9 mt-3 mt-md-4">
                {Utility.numberToString(application?.diagnosisAge, "N/A")}
              </div>
              <div className="col-6 col-md-3 mt-3 mt-md-4">
                <label className="bds-summary-label">Disability Affects Daily Life:</label>
              </div>
              <div className="col-6 col-md-9 mt-3 mt-md-4">
                <div className="text-break">
                  {Utility.defaultIfNull(application?.disabilityDescription, "N/A")}
                </div>
              </div>
            </div>
          </Card>
        </div>

        <div className="mt-4">
          <Card>
            <div className="row">
              <div className="col-8">
                <div className="bds-summary-section-heading">Additional Applicant Information</div>
              </div>
              <div className="col-4 text-end">
                <button type="button" disabled={isLoading}
                  onClick={() => NavigationHelper.gotoApplicantDemographics(navigate, applicationRefId)}
                  className="btn bds-btn-primary">Edit Section</button>
              </div>
            </div>
            <hr className="bds-divider mt-3" />
            <div className="row">
              <div className="col-6 col-md-3 mt-3 mt-md-4">
                <label className="bds-summary-label">Gender:</label>
              </div>
              <div className="col-6 col-md-3 mt-3 mt-md-4">
                {Utility.defaultIfNull(application?.genderName, "")}
              </div>
              <div className="col-6 col-md-3 mt-3 mt-md-4">
                <label className="bds-summary-label">Vocational Rehabilitation:</label>
              </div>
              <div className="col-6 col-md-3 mt-3 mt-md-4">
                {Utility.toTriStateString(application?.hasAssessedByVocationRehab, "Yes", "No", "")}
              </div>
              <div className="col-6 col-md-3 mt-3">
                <label className="bds-summary-label">Marital Status:</label>
              </div>
              <div className="col-6 col-md-3 mt-3">
                {Utility.defaultIfNull(application?.maritalStatusName, "")}
              </div>
              <div className="col-6 col-md-3 mt-3">
                <label className="bds-summary-label">Preferred Language:</label>
              </div>
              <div className="col-6 col-md-3 mt-3">
                {application?.preferredLanguageId == 16 ?
                  application?.preferredLanguageOther :
                  Utility.defaultIfNull(application?.preferredLanguageName, "None")}
              </div>
              <div className="col-6 col-md-3 mt-3">
                <label className="bds-summary-label">Student Status:</label>
              </div>
              <div className="col-6 col-md-3 mt-3">
                {Utility.toTriStateString(application?.isStudent, "Yes", "No", "")}
              </div>
              <div className="col-6 col-md-3 mt-3">
                <label className="bds-summary-label">Hispanic or Latino:</label>
              </div>
              <div className="col-6 col-md-3 mt-3">
                {Utility.toTriStateString(application?.isHispanicOrLatino, "Yes", "No", "")}
              </div>
              <ShowThen when={application?.isStudent}>
                <div className="col-6 col-md-3 mt-3">
                  <label className="bds-summary-label">Educational Enrollment:</label>
                </div>
                <div className="col-6 col-md-3 mt-3">
                  {Utility.defaultIfNull(application?.educationalTypeName, "")}
                </div>
              </ShowThen>
              <ShowThen when={!application?.isStudent}>
                <div className="col-6 col-md-3 mt-3">
                  <label className="bds-summary-label">Highest Education:</label>
                </div>
                <div className="col-6 col-md-3 mt-3">
                  {Utility.defaultIfNull(application?.educationalTypeName, "")}
                </div>
              </ShowThen>
              <div className="col-6 col-md-3 mt-3">
                <label className="bds-summary-label">Race:</label>
              </div>
              <div className="col-6 col-md-3 mt-3">
                <ShowThen when={application?.isWhite}>
                  <div>White</div>
                </ShowThen>
                <ShowThen when={application?.isBlackAfricanAmerican}>
                  <div>Black or African American</div>
                </ShowThen>
                <ShowThen when={application?.isAsian}>
                  <div>Asian</div>
                </ShowThen>
                <ShowThen when={application?.isAmericanIndianAlaskanNative}>
                  <div>American Indian or Alaskan Native</div>
                </ShowThen>
                <ShowThen when={application?.isNativeHawaiianPacificIslander}>
                  <div>Native Hawaiian or Pacific Islander</div>
                </ShowThen>
                <ShowThen when={application?.isMultiRacial}>
                  <div>Multi-Racial</div>
                </ShowThen>
              </div>
              <div className="col-6 col-md-3 mt-3">
                <label className="bds-summary-label">Legal Representation:</label>
              </div>
              <div className="col-6 col-md-3 mt-3">
                {Utility.toTriStateString(application?.hasLegalRepresentative, "Yes", "No", "")}
              </div>
            </div>
          </Card>
        </div>

        <ShowThen when={application?.hasLegalRepresentative}>
          <div className="mt-4">
            <Card>
              <div className="row">
                <div className="col-8">
                  <div className="bds-summary-section-heading">Legal Representative Information</div>
                </div>
                <div className="col-4 text-end">
                  <button type="button" disabled={isLoading}
                    onClick={() => NavigationHelper.gotoLegalRepresentative(navigate, applicationRefId)}
                    className="btn bds-btn-primary">Edit Section</button>
                </div>
              </div>
              <hr className="bds-divider mt-3" />
              <div className="row">
                <div className="col-6 col-md-3 mt-3 mt-md-4">
                  <label className="bds-summary-label">Legal Rep. Name:</label>
                </div>
                <div className="col-6 col-md-3 mt-3 mt-md-4">
                  <ShowThen when={application?.primaryLegalRepresentative?.firstName}>
                    {application?.primaryLegalRepresentative?.firstName} {" "}
                  </ShowThen>
                  <ShowThen when={application?.primaryLegalRepresentative?.middleName}>
                    {application?.primaryLegalRepresentative?.middleName} {" "}
                  </ShowThen>
                  <ShowThen when={application?.primaryLegalRepresentative?.lastName}>
                    {application?.primaryLegalRepresentative?.lastName} {" "}
                  </ShowThen>
                  <ShowThen when={application?.primaryLegalRepresentative?.suffixTypeName}>
                    {application?.primaryLegalRepresentative?.suffixTypeName}
                  </ShowThen>
                </div>
                <div className="col-6 col-md-3 mt-3 mt-md-4">
                  <label className="bds-summary-label">Relationship:</label>
                </div>
                <div className="col-6 col-md-3 mt-3 mt-md-4">
                  {Utility.defaultIfNull(application?.primaryLegalRepresentative?.legalRepresentativeTypeName, "")}
                </div>
                <div className="col-6 col-md-3 mt-3">
                  <label className="bds-summary-label">Preferred Language:</label>
                </div>
                <div className="col-6 col-md-3 mt-3">
                  {application?.primaryLegalRepresentative?.preferredLanguageId == 16 ?
                    application?.primaryLegalRepresentative?.preferredLanguageOther :
                    Utility.defaultIfNull(application?.primaryLegalRepresentative?.preferredLanguageName, "None")}
                </div>
                <div className="col-6 col-md-3 mt-3">
                  <label className="bds-summary-label">Home:</label>
                </div>
                <div className="col-6 col-md-3 mt-3">
                  {formatPhoneNumber(application?.primaryLegalRepresentative?.homePhone?.phoneNumber, "N/A")}
                </div>
                <div className="col-6 col-md-3 mt-3">
                  <label className="bds-summary-label">Primary Phone:</label>
                </div>
                <div className="col-6 col-md-3 mt-3">
                  {getLegalRepresentativePrimaryPhoneNumber(application?.primaryLegalRepresentative, "N/A")}
                </div>
                <div className="col-6 col-md-3 mt-3">
                  <label className="bds-summary-label">Mobile:</label>
                </div>
                <div className="col-6 col-md-3 mt-3">
                  {formatPhoneNumber(application?.primaryLegalRepresentative?.mobilePhone?.phoneNumber, "N/A")}
                </div>
                <div className="col-6 col-md-3 mt-3">
                  <label className="bds-summary-label">Email Address:</label>
                </div>
                <div className="col-6 col-md-3 mt-3">
                  {Utility.defaultIfNull(application?.primaryLegalRepresentative?.emailAddress, "N/A")}
                </div>
                <div className="col-6 col-md-3 mt-3">
                  <label className="bds-summary-label">Work:</label>
                </div>
                <div className="col-6 col-md-3 mt-3">
                  {formatPhoneNumber(application?.primaryLegalRepresentative?.workPhone?.phoneNumber, "N/A")}
                </div>
                <ShowThen when={application?.primaryLegalRepresentative?.mailingAddress &&
                  !application?.primaryLegalRepresentative?.mailingAddress?.isInternational}>
                  <div className="col-6 col-md-3 mt-3">
                    <label className="bds-summary-label">Mailing Address:</label>
                  </div>
                  <div className="col-6 col-md-3 mt-3">
                    {formatAddress(application?.primaryLegalRepresentative?.mailingAddress)}
                  </div>
                </ShowThen>
                <ShowThen when={application?.primaryLegalRepresentative?.mailingAddress &&
                  !application?.primaryLegalRepresentative?.mailingAddress?.isInternational}>
                  <div className="col-6 col-md-3 mt-3">
                    <label className="bds-summary-label">Mailing County:</label>
                  </div>
                  <div className="col-6 col-md-3 mt-3">
                    {Utility.defaultIfNull(application?.primaryLegalRepresentative?.mailingAddress?.countyName, "N/A")}
                  </div>
                </ShowThen>
                <ShowThen when={application?.primaryLegalRepresentative?.hasDifferentCurrentAddress &&
                  application?.primaryLegalRepresentative?.currentAddress &&
                  !application?.primaryLegalRepresentative?.currentAddress?.isInternational}>
                  <div className="col-6 col-md-3 mt-3">
                    <label className="bds-summary-label">Current Address:</label>
                  </div>
                  <div className="col-6 col-md-3 mt-3">
                    {formatAddress(application?.primaryLegalRepresentative?.currentAddress)}
                  </div>
                </ShowThen>
                <ShowThen when={application?.primaryLegalRepresentative?.hasDifferentCurrentAddress &&
                  application?.primaryLegalRepresentative?.currentAddress &&
                  !application?.primaryLegalRepresentative?.currentAddress?.isInternational}>
                  <div className="col-6 col-md-3 mt-3">
                    <label className="bds-summary-label">Current County:</label>
                  </div>
                  <div className="col-6 col-md-3 mt-3">
                    {Utility.defaultIfNull(application?.primaryLegalRepresentative?.currentAddress?.countyName, "N/A")}
                  </div>
                </ShowThen>
                <ShowThen when={application?.primaryLegalRepresentative?.mailingAddress &&
                  application?.primaryLegalRepresentative?.mailingAddress?.isInternational}>
                  <div className="col-6 col-md-3 mt-3">
                    <label className="bds-summary-label">International Address:</label>
                  </div>
                  <div className="col-6 col-md-3 mt-3">
                    {Utility.defaultIfNull(application?.primaryLegalRepresentative?.mailingAddress?.internationalAddress, "N/A")}
                  </div>
                </ShowThen>
                <ShowThen when={application?.primaryLegalRepresentative?.hasDifferentCurrentAddress &&
                  application?.primaryLegalRepresentative?.currentAddress &&
                  application?.primaryLegalRepresentative?.currentAddress?.isInternational}>
                  <div className="col-6 col-md-3 mt-3">
                    <label className="bds-summary-label">Current International Address:</label>
                  </div>
                  <div className="col-6 col-md-3 mt-3">
                    {Utility.defaultIfNull(application?.primaryLegalRepresentative?.currentAddress?.internationalAddress, "N/A")}
                  </div>
                </ShowThen>
                <ShowThen when={!application?.primaryLegalRepresentative?.hasDifferentCurrentAddress || false }>
                  <div className="col-6 col-md-3 mt-3">
                    <label className="bds-summary-label">Current Address:</label>
                  </div>
                  <div className="col-6 col-md-3 mt-3">
                    Same as mailing
                  </div>
                </ShowThen>
              </div>
            </Card>
          </div>
        </ShowThen>

        <ShowThen when={application?.hasSecondaryLegalRepresentative || false}>
          <div className="mt-4">
            <Card>
              <div className="row">
                <div className="col-8">
                  <div className="bds-summary-section-heading">Secondary Legal Representative Information</div>
                </div>
                <div className="col-4 text-end">
                  <button type="button" disabled={isLoading}
                    onClick={() => NavigationHelper.gotoLegalRepresentative(navigate, applicationRefId)}
                    className="btn bds-btn-primary">Edit Section</button>
                </div>
              </div>
              <hr className="bds-divider mt-3" />
              <div className="row">
                <div className="col-6 col-md-3 mt-3 mt-md-4">
                  <label className="bds-summary-label">Legal Rep. Name:</label>
                </div>
                <div className="col-6 col-md-3 mt-3 mt-md-4">
                  <ShowThen when={application?.secondaryLegalRepresentative?.firstName}>
                    {application?.secondaryLegalRepresentative?.firstName} {" "}
                  </ShowThen>
                  <ShowThen when={application?.secondaryLegalRepresentative?.middleName}>
                    {application?.secondaryLegalRepresentative?.middleName} {" "}
                  </ShowThen>
                  <ShowThen when={application?.secondaryLegalRepresentative?.lastName}>
                    {application?.secondaryLegalRepresentative?.lastName} {" "}
                  </ShowThen>
                  <ShowThen when={application?.secondaryLegalRepresentative?.suffixTypeName}>
                    {application?.secondaryLegalRepresentative?.suffixTypeName}
                  </ShowThen>
                </div>
                <div className="col-6 col-md-3 mt-3 mt-md-4">
                  <label className="bds-summary-label">Relationship:</label>
                </div>
                <div className="col-6 col-md-3 mt-3 mt-md-4">
                  {application?.secondaryLegalRepresentative?.legalRepresentativeTypeName}
                </div>
                <div className="col-6 col-md-3 mt-3">
                  <label className="bds-summary-label">Preferred Language:</label>
                </div>
                <div className="col-6 col-md-3 mt-3">
                  {application?.secondaryLegalRepresentative?.preferredLanguageId == 16 ?
                    application?.secondaryLegalRepresentative?.preferredLanguageOther :
                    Utility.defaultIfNull(application?.secondaryLegalRepresentative?.preferredLanguageName, "None")}
                </div>
                <div className="col-6 col-md-3 mt-3">
                  <label className="bds-summary-label">Home:</label>
                </div>
                <div className="col-6 col-md-3 mt-3">
                  {formatPhoneNumber(application?.secondaryLegalRepresentative?.homePhone?.phoneNumber, "N/A")}
                </div>
                <div className="col-6 col-md-3 mt-3">
                  <label className="bds-summary-label">Primary Phone:</label>
                </div>
                <div className="col-6 col-md-3 mt-3">
                  {getLegalRepresentativePrimaryPhoneNumber(application?.secondaryLegalRepresentative, "N/A")}
                </div>
                <div className="col-6 col-md-3 mt-3">
                  <label className="bds-summary-label">Mobile:</label>
                </div>
                <div className="col-6 col-md-3 mt-3">
                  {formatPhoneNumber(application?.secondaryLegalRepresentative?.mobilePhone?.phoneNumber, "N/A")}
                </div>
                <div className="col-6 col-md-3 mt-3">
                  <label className="bds-summary-label">Email Address:</label>
                </div>
                <div className="col-6 col-md-3 mt-3">
                  {Utility.defaultIfNull(application?.secondaryLegalRepresentative?.emailAddress, "N/A")}
                </div>
                <div className="col-6 col-md-3 mt-3">
                  <label className="bds-summary-label">Work:</label>
                </div>
                <div className="col-6 col-md-3 mt-3">
                  {formatPhoneNumber(application?.secondaryLegalRepresentative?.workPhone?.phoneNumber, "N/A")}
                </div>
                <ShowThen when={application?.secondaryLegalRepresentative?.mailingAddress &&
                  !application?.secondaryLegalRepresentative?.mailingAddress?.isInternational}>
                  <div className="col-6 col-md-3 mt-3">
                    <label className="bds-summary-label">Mailing Address:</label>
                  </div>
                  <div className="col-6 col-md-3 mt-3">
                    {formatAddress(application?.secondaryLegalRepresentative?.mailingAddress)}
                  </div>
                </ShowThen>
                <ShowThen when={application?.secondaryLegalRepresentative?.mailingAddress &&
                  !application?.secondaryLegalRepresentative?.mailingAddress?.isInternational}>
                  <div className="col-6 col-md-3 mt-3">
                    <label className="bds-summary-label">Mailing County:</label>
                  </div>
                  <div className="col-6 col-md-3 mt-3">
                    {Utility.defaultIfNull(application?.secondaryLegalRepresentative?.mailingAddress?.countyName, "N/A")}
                  </div>
                </ShowThen>
                <ShowThen when={application?.secondaryLegalRepresentative?.hasDifferentCurrentAddress &&
                  application?.secondaryLegalRepresentative?.currentAddress &&
                  !application?.secondaryLegalRepresentative?.currentAddress?.isInternational}>
                  <div className="col-6 col-md-3 mt-3">
                    <label className="bds-summary-label">Current Address:</label>
                  </div>
                  <div className="col-6 col-md-3 mt-3">
                    {formatAddress(application?.secondaryLegalRepresentative?.currentAddress)}
                  </div>
                </ShowThen>
                <ShowThen when={!application?.secondaryLegalRepresentative?.hasDifferentCurrentAddress || false }>
                  <div className="col-6 col-md-3 mt-3">
                    <label className="bds-summary-label">Current Address:</label>
                  </div>
                  <div className="col-6 col-md-3 mt-3">
                    Same as mailing
                  </div>
                </ShowThen>
                <ShowThen when={application?.secondaryLegalRepresentative?.hasDifferentCurrentAddress &&
                  application?.secondaryLegalRepresentative?.currentAddress &&
                  !application?.secondaryLegalRepresentative?.currentAddress?.isInternational}>
                  <div className="col-6 col-md-3 mt-3">
                    <label className="bds-summary-label">Current County:</label>
                  </div>
                  <div className="col-6 col-md-3 mt-3">
                    {Utility.defaultIfNull(application?.secondaryLegalRepresentative?.currentAddress?.countyName, "N/A")}
                  </div>
                </ShowThen>
                <ShowThen when={application?.secondaryLegalRepresentative?.mailingAddress &&
                  application?.secondaryLegalRepresentative?.mailingAddress?.isInternational}>
                  <div className="col-6 col-md-3 mt-3">
                    <label className="bds-summary-label">International Address:</label>
                  </div>
                  <div className="col-6 col-md-3 mt-3">
                    {Utility.defaultIfNull(application?.secondaryLegalRepresentative?.mailingAddress?.internationalAddress, "N/A")}
                  </div>
                </ShowThen>
                <ShowThen when={application?.secondaryLegalRepresentative?.hasDifferentCurrentAddress &&
                  application?.secondaryLegalRepresentative?.currentAddress &&
                  application?.secondaryLegalRepresentative?.currentAddress?.isInternational}>
                  <div className="col-6 col-md-3 mt-3">
                    <label className="bds-summary-label">Current International Address:</label>
                  </div>
                  <div className="col-6 col-md-3 mt-3">
                    {Utility.defaultIfNull(application?.secondaryLegalRepresentative?.currentAddress?.internationalAddress, "N/A")}
                  </div>
                </ShowThen>
              </div>
            </Card>
          </div>
        </ShowThen>

      </ShowThen>

      <div className="row mt-4">
        <div className="col-12 col-md-6 order-1 order-md-0 mt-3 mt-md-0">
          <button type="button"
            onClick={() => {
              if (application?.hasLegalRepresentative) {
                NavigationHelper.gotoLegalRepresentative(navigate, applicationRefId);
              } else {
                NavigationHelper.gotoApplicantDemographics(navigate, applicationRefId);
              }
            }}
            className="btn bds-btn-primary" >Back</button>
          <IsAuthenticated>
            <button type="button" className="btn bds-btn-primary ms-0 ms-md-3 mt-3 mt-md-0"
            onClick={() => NavigationHelper.gotoDashboard(navigate)}>Save for Later</button>
          </IsAuthenticated>
        </div>
        <div className="col-12 col-md-6 order-0 order-md-1 text-start text-md-end">
          <button type="button"
            onClick={() => NavigationHelper.goToDocumentUpload(navigate, applicationRefId)}
            className="btn bds-btn-primary" disabled={isLoading} >Next</button>
        </div>
      </div>

    </>
  );
}

export default ApplicantionSummary