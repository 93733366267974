// --GLOBAL_LIBRARY
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Form } from 'react-bootstrap';

// --APP_MODULE
// -- --  ./local_component_imports
import { INbarTreatmentNeeds, NbarTreatmentNeeds } from "./treatment-information.store";
import { NbarTreatmentInformationValidationSchema } from "./treatment-information.validation";

// -- --  ../extensions
import { PageTitleSetter } from "../../../extensions/page-title-setter/page-title-setter.component";
import { ApplicationProgressBar } from "../../../extensions/application-progress-bar/application-progress-bar.component";


// --SHARED_MODULE
// -- --  ../resources
import { LookupResource } from "../../../../shared-module/api-resources/lookup.resource";
import { NbarTreatmentResource } from "../../../../shared-module/api-resources/Nbar/nbar-treatment.resource";
import { NbarApplicationResource } from "../../../../shared-module/api-resources/Nbar/nbar-application.resource";
import { NbarOtherResource } from "../../../../shared-module/api-resources/Nbar/nbar-other.resource";

// -- --  ../components
import { Card } from "../../../../shared-module/components/card/card.component";
import { ShowThen } from "../../../../shared-module/components/show-then/show-then.component";
import { BdsMultiSelect } from "../../../../shared-module/components/multi-select/multi-select.component";
import { DebugContainer } from "../../../../shared-module/components/debug-container/debug-container.component";
import { RadioButtonGroup } from "../../../../shared-module/components/radio-button-group/radio-button-group.component";
import { NbarTextArea } from "../../../../shared-module/components/nbar-text-area/nbar-text-area.component";
import { ErrorContainer } from "../../../../shared-module/components/error-container/error-container.component";
import { IsAuthenticated } from "../../../../shared-module/components/is-authenticated/is-authenticated";

// -- --  ../helpers
import * as enums from '../../../../shared-module/helpers/enums'
import { NavigationHelper } from "../../../../shared-module/helpers/navigation-helper";
import { Utility } from "../../../../shared-module/helpers/utility";

// -- --  ../hooks
import { useFormValidation } from "../../../../shared-module/hooks/use-form-validation/use-form-validation.hook";

// -- --  ../models
import { GatewayOapApi } from "../../../../shared-module/models/gateway-oap-api.models";
import { ApplicationExpirationError, GatewayNetworkError } from "../../../../shared-module/models/common.models";

// -- --  ../services
import { ApiConfig } from "../../../../shared-module/services/api-config";
import { SessionService } from "../../../../shared-module/services/session.service";
import { EventBroadcasterService } from "shared-module/events/event-broadcaster.service";


function TreatmentInformation() {
    // -- INSTANTIATIONS
	// view model
    const store = NbarTreatmentNeeds;

    // ./hooks
    const { id: nbarApplicationRefId } = useParams();
    const navigate = useNavigate();
    const { errors, touched, setIsSubmitting, getFieldState, setFieldsTouched, touchedAll } = 
        useFormValidation(NbarTreatmentInformationValidationSchema, store);

    // ./resources
    const lookupService = new LookupResource(ApiConfig);
    const applicationService = new NbarApplicationResource(ApiConfig);
    const treatmentService = new NbarTreatmentResource(ApiConfig);
    const otherService = new NbarOtherResource(ApiConfig);

    // ./form processing
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [savedState, setSavedState] = useState<INbarTreatmentNeeds>({} as INbarTreatmentNeeds);
    const [isSaving, setIsSaving] = useState<boolean>(false);

    // ../component state
    const [treatmentInformationId, setTreatmentInformationId] = useState<number>(-1);
    const [YesNoRadioOptions, setYesNoRadioOptions] = useState([]);
    const [YesNoNARadioOptions, setYesNoNARadioOptions] = useState([]);
    const [StudentStatusRadioOptions, setStudentStatusRadioOptions] = useState([]);
    
    const [medicationErrors, setMedicationErrors] = useState<string>('');
    const [medicationChangeErrors, setMedicationChangeErrors] = useState<string>('');
    const [medicationList, setMedicationList] = useState<GatewayOapApi.MedicationInformationModel[]>([]);

    const [savedTreatmentOptions, setSavedTreatmentOptions] = useState<GatewayOapApi.MedicalInformationModel[]>([]);
    const [savedAdditionalServiceOptions, setSavedAdditionalServiceOptions] = useState<GatewayOapApi.AdditionalBehaviorServicesModel[]>([]);

    // LOCAL FORM PROCESSING
    // ../MedicationGrid
    const handleAddMedication = (event) => {
        event.preventDefault();
        store.setTriggerMedicationValidation(true);
        NbarTreatmentInformationValidationSchema.validateAt('medicationValidationMessages', store)
        .then(() => {
            errors['medicationValidationMessages'] = ''; 
            otherService.addOrUpdateMedicationInformation(null, treatmentInformationId, store.addMedicationName, store.addMedicationDescription, null)
            .then(() => {
                return treatmentService.getNbarTreatmentInformation(treatmentInformationId);
            })
            .then((response) => {
                console.log(`Response Record: ${response.record}`);
                setMedicationList(response.record?.medicationInformation);
                store.refresh(response.record);
            })      
            setMedicationErrors('');
        })
        .catch(err => {
            errors['medicationValidationMessages'] = err.message;
            setMedicationErrors(errors["medicationValidationMessages"].message);
        })
        .finally(() => {
            store.setTriggerMedicationValidation(false);
            setFieldsTouched("medicationList", "medicationFrequencies")
        })
    }

    const handleFrequencyUpdate = (event) => {
        event?.preventDefault();
        store.setTriggerMedicationChangeValidation(true);
        NbarTreatmentInformationValidationSchema.validateAt('medicationChangeValidationMessages', store)
        .then(() => {
            errors['medicationChangeValidationMessages'] = '';         
            const selectedMedication = medicationList.filter(x => x.medicationInformationId == Number(store.selectedMedicationForChange))[0];
            // console.log(`selected medication: ${selectedMedication.medicationInformationId}`);
            otherService.addOrUpdateMedicationInformation(selectedMedication.medicationInformationId, 
                treatmentInformationId, 
                selectedMedication.medicationName, 
                selectedMedication.prescriptionReason, 
                Number(store.addMedicationLastChanged)
            ).then(() => {
                return treatmentService.getNbarTreatmentInformation(treatmentInformationId);
            }).then((response) => {
                setMedicationList(response.record.medicationInformation);
                store.refresh(response.record);
            });
            setMedicationChangeErrors('');
        })
        .catch(err => {
            errors['medicationChangeValidationMessages'] = err.message;
            setMedicationChangeErrors(errors["medicationChangeValidationMessages"].message);
        })
        .finally(() => {
            store.setTriggerMedicationChangeValidation(false);
        })      
    }

    const handleRemoveMedication = (event) => {
        event.preventDefault();
        otherService.deleteMedicationInformation(Number(event.target.name))
        .then(() => {
            return treatmentService.getNbarTreatmentInformation(treatmentInformationId)
            .then((response) => {
                setMedicationList(response.record.medicationInformation)
                store.refresh(response.record);
            })     
        })
    }

    async function clearAllMedications() : Promise<boolean> {
        store.medicationList.forEach((item) => {
            // console.log('clearing medication: ', item.medicationInformationId);
            otherService.deleteMedicationInformation(item.medicationInformationId);
        });
        return true;
    }

    async function clearAllMedicationIntervals() : Promise<boolean> {
        store.medicationList.forEach((item) => {
            // console.log('clearing medication intervals: ', item.medicationInformationId);
            otherService.addOrUpdateMedicationInformation(item.medicationInformationId, item.treatmentInformationId, item.medicationName, item.prescriptionReason, null);
        });
        return true;
    }

    function checkClearAllMedications(event) {
        const targetValue = Number(event.target.value);
        store.hasMedications = targetValue;

        if(targetValue == enums.YesNoNARadioButtonGroupOptions.No) {
            clearAllMedications()
            .then((response) => {
                // saveTreatmentInformation("hasMedications")
                saveTreatmentInformation()
                .then((response) => {
                    treatmentService.getNbarTreatmentInformation(treatmentInformationId)
                    .then((response) => {
                        setMedicationList(response.record.medicationInformation)
                        store.refresh(response.record);
                    })  
                }) 
            })       
        }
    }

    function checkClearAllMedicationFrequencies(event) {
        console.log('checkClearAllMedicationFrequencies');
        const targetValue = Number(event.target.value);
        store.hasChangesInMedication = targetValue;

        if(targetValue == enums.YesNoNARadioButtonGroupOptions.No) {
            clearAllMedicationIntervals()
            .then((response) => {
                saveTreatmentInformation()
                // saveTreatmentInformation("hasChangesInMedication")
                .then((response) => {
                    treatmentService.getNbarTreatmentInformation(treatmentInformationId)
                    .then((response) => {
                        setMedicationList(response.record.medicationInformation)
                        store.refresh(response.record);
                    })  
                }) 
            })       
        }
    }

    const handleRemoveInterval = (event) => {
        event.preventDefault();
        const selectedMedication = medicationList.filter(x => x.medicationInformationId == Number(event.target.name))[0];

        otherService.addOrUpdateMedicationInformation(selectedMedication.medicationInformationId, selectedMedication.treatmentInformationId, selectedMedication.medicationName, selectedMedication.prescriptionReason, null)
        .then((response) => {
            treatmentService.getNbarTreatmentInformation(treatmentInformationId)
            .then((response) => {
                setMedicationList(response.record.medicationInformation)
                store.refresh(response.record);
            })  

        })
        // handleFrequencyUpdate(event);
        store.setSelectedMedicationForChange(null);
    }


    // ../TreatmentOptions
    const handleUpdateTreatmentType = (event) => {
        const selectedIds = store.selectedTreatmentOptions.map(({value}) => Number(value));
        const savedIds = savedTreatmentOptions.map((item) => item.medicalInformationId);

        console.log(`SELECTED IDS: ${selectedIds} | SAVED IDS: ${savedIds}`);
        if(selectedIds.length > savedIds.length) {
            // if selectedIds > savedIds, we are adding - find which one (should be the last one added)
            const addId = selectedIds.slice(-1)[0];
            otherService.addMedicalInformation(treatmentInformationId, addId)
            .then(() => {
                treatmentService.getNbarTreatmentInformation(treatmentInformationId)
                .then((response) => {
                    const savedOptions = response.record?.medicalInformation;
                    setSavedTreatmentOptions(savedOptions);
                })
            })
        }
        else if (selectedIds.length < savedIds.length) {
            // if selectedIds < savedIds, we are deleting
            const deleteId = savedIds.filter(x => !selectedIds.includes(x));
            // console.log(`DELETED ID: ${deleteId} | ${deleteId[0]}`)
            otherService.deleteMedicalInformation(deleteId[0])
            .then(() => {
                treatmentService.getNbarTreatmentInformation(treatmentInformationId)
                .then((response) => {
                    const savedOptions = response.record?.medicalInformation;
                    setSavedTreatmentOptions(savedOptions);
                })
            })
        } 
    }

    const handleUpdateAdditionalServices = (event) => {
        const selectedIds = store.selectedAdditionalServiceOptions.map(({value}) => Number(value));
        const savedIds = savedAdditionalServiceOptions.map((item) => item.additionalBehaviorServiceId);

        // console.log(`SELECTED IDS: ${selectedIds} | SAVED IDS: ${savedIds}`);
        if(selectedIds.length > savedIds.length) {
            // if selectedIds > savedIds, we are adding - find which one (should be the last one added)
            const addId = selectedIds.slice(-1)[0];
            otherService.addAdditionalBehaviorService(treatmentInformationId, addId)
            .then(() => {
                treatmentService.getNbarTreatmentInformation(treatmentInformationId)
                .then((response) => {
                    const savedOptions = response.record?.additionalBehaviorServices;
                    setSavedAdditionalServiceOptions(savedOptions);
                })
            })
        }
        else if (selectedIds.length < savedIds.length) {
            // if selectedIds < savedIds, we are deleting
            const deleteId = savedIds.filter(x => !selectedIds.includes(x));
            // console.log(`DELETED ID: ${deleteId} | ${deleteId[0]}`)
            otherService.deleteAdditionalBehaviorService(deleteId[0])
            .then(() => {
                treatmentService.getNbarTreatmentInformation(treatmentInformationId)
                .then((response) => {
                    const savedOptions = response.record?.additionalBehaviorServices;
                    setSavedAdditionalServiceOptions(savedOptions);
                })
            })
        } 
    }

    function checkClearAllAdditionalServiceOptions(event) {
        // console.log(`checkClearAllAdditionalServiceOptions: ${event}`);
        if(event == enums.YesNoNARadioButtonGroupOptions.No) {
            savedAdditionalServiceOptions.forEach((item) => {
                otherService.deleteAdditionalBehaviorService(item.additionalBehaviorServiceId)
            })
        }
        setSavedAdditionalServiceOptions([]);
    }


    // GLOBAL FORM PROCESSING AND NAVIGATION
    // ../"Public Methods"
    function handleValueChange<T>(event, eventSource: string, currentValue: T, savedValue: T) {
        if (currentValue === savedValue) { return; }
        setIsSubmitting(true, eventSource);
        submit()
        .finally(() => {
            setIsSubmitting(false, eventSource);
        })
    }

    const handleGoToNextStep = (event) => {
        validate().then(() => {
            setIsSaving(true);
            submit().then(() => {
                NavigationHelper.gotoNbarApplicantPreferences(navigate, nbarApplicationRefId);
            })     
        })
        .catch((error) => {
            scrollTo(0, 0);
        })
        .finally(() => {
            setIsSaving(false);
        })        
    }

    // ../"Private Methods"
    function submit() : Promise<any> {
        // the call to applicationService.getApplication() is needed to ensure the application has not expired.
        // TODO: refactor this out for nBar. We do not want to pull the application data on each call.
        // --- will need an alternate way of determining current application status
        setIsSaving(true); 
        return applicationService.getApplication(nbarApplicationRefId)
        .then(() => {
            saveTreatmentInformation()
            .then(() => {
                var guestSessionId = sessionStorage.getItem("guestSessionId") || "0";
                if(guestSessionId != "0") {
                    SessionService.slideGuestSession(Number(guestSessionId));
                    EventBroadcasterService.RaiseGuestSessionExpirationEvent({
                        isAuthenticated: false,
                        remainingTimeInSeconds: 900,
                        hasActiveMaintenance: false
                    });
                }
            })  
        })     
        .catch((ex) => { 
            if(ex instanceof ApplicationExpirationError) {
                Utility.alert("Application Timeout", ex.errorMessage);
            }
            else if (!(ex instanceof GatewayNetworkError)) {
                Utility.alert("Error Saving", ex);
            }
            NavigationHelper.gotoNbarHome(navigate);
        })
        .finally(() => {
            setIsSaving(false);
        })
    }

    function validate() : Promise<any> {
        touchedAll();
        return NbarTreatmentInformationValidationSchema.validate(store)
        .then((response) => {
            NbarTreatmentInformationValidationSchema.isValidSync(store);
        })
        .catch((error) => {
            throw error;
        });
    }

    function saveTreatmentInformation(): Promise<any> {
        return treatmentService.saveNbarTreatmentInformation(
            treatmentInformationId, 
            store.treatmentNeedsDescription,
            store.hasPrimaryCarePhysician,
            store.primaryCarePhysicianName,
            store.medicalDiagnoses,
            store.hasMedications,
            store.isUnderPsychiatricCare,
            store.psychiatristName,
            store.psychiatristDiagnoses,
            store.hasRefusedMedication,
            store.hasRefusedMedicationDescription,
            store.hasBehaviorManagmentService,
            store.hasBehaviorManagmentConsultation,
            store.hasTriggerEvents,
            store.triggerEventsDescription,
            store.hasAdditionalServices,
            store.hasRecentHospitalizations,
            store.recentHospitalizationDescription,
            store.hasPastLawEnforcement,
            store.pastLawEnforcementDescription,
            store.hasStructuredRoutine,
            store.routineDescription,
            store.participatesInCommunityActivities,
            store.communityActivityDescription,
            store.meaningfulDayDescription,
            store.capacityOfWaiverUsage,
            store.isStudent,
            store.studentStatus == enums.StudentStatusRadioButtonGroupOptions.FullTime ? 1 : 0,
            store.studentStatus == enums.StudentStatusRadioButtonGroupOptions.PartTime ? 1 : 0,
            store.studentWeeklySchedule,
            store.hasSchoolBehaviors,
            store.hasIEP,
            store.schoolBehaviorFrequency,
            store.schoolBehaviorDescription,
            null
        )
        .catch(ex => {
            throw ex;
        })
    }

    // PAGE LOAD
    useEffect(() => {      
        setIsLoading(true);
        Utility.showLoadingOverlay();
        setYesNoRadioOptions(Utility.getRadioGroupYesNoOptions(false));
        setYesNoNARadioOptions(Utility.getRadioGroupYesNoOptions(true));
        setStudentStatusRadioOptions(Utility.getStudentStatusRadioOptions(false));
        Promise.all([
            applicationService.getApplication(nbarApplicationRefId),
            lookupService.getTreatmentTypes(),
            lookupService.getPrescriptionChangeTypes(),
            lookupService.getBehaviorManagementServiceTypes(),
            lookupService.getBehaviorFrequencyTypes()
        ])
        .then((response) => {
            store.setTreatmentNeedsOptions(response[1].records);
            store.setMedicationChangeIntervalOptions(response[2].records);
            store.setBehavioralServiceOptions(response[3].records);
            store.setSchoolBehaviorFrequencyOptions(response[4].records);
            setTreatmentInformationId(response[0].record?.treatmentInformationId);
            return treatmentService.getNbarTreatmentInformation(response[0].record?.treatmentInformationId);
        })
        .then((response) => {
            store.refresh(response.record);
            setSavedState(store.buildState(response.record));
            setSavedTreatmentOptions(response.record?.medicalInformation);
            setSavedAdditionalServiceOptions(response.record?.additionalBehaviorServices);
            setMedicationList(response.record?.medicationInformation);          
        })
        .catch((ex) => {
            if(ex instanceof ApplicationExpirationError) {
                Utility.alert("Application Timeout", ex.errorMessage);
            }
            else if (!(ex instanceof GatewayNetworkError)) {
                Utility.alert("Error Saving", ex);
            }
            NavigationHelper.gotoNbarHome(navigate); 
        })
        .finally(() => {
            setIsLoading(false);
            Utility.hideLoadingOverlay();
        });
    }, [])

    // COMPONENT MARKUP
    return (
        <>
            <DebugContainer data={store} saved={savedState} touched={touched} errors={errors}></DebugContainer>
            <PageTitleSetter step={3} totalSteps={6}></PageTitleSetter>
            <ShowThen when={!isLoading}>
                <ErrorContainer data={store} saved={savedState} touched={touched} errors={errors}></ErrorContainer>
            </ShowThen>

            <div className="mt-4">
                <ApplicationProgressBar step={3} totalSteps={6}></ApplicationProgressBar>
            </div>

            <form>
                {/* MEDICAL INFORMATION */}
                <div className="mt-4">
                    <Card>
                        <div className="bds-section-heading mandatory">Medical Information</div>
                        <div className="row mt-3">
                            <div className="col-md-6">
                                <label className="form-check-label" htmlFor="available-treatment-needs">What treatment needs are being explored? (You may select more than one option)</label>
                                <BdsMultiSelect name="selectedTreatmentOptions"
                                    value={store.selectedTreatmentOptions}
                                    onChange={(event) => {
                                        setFieldsTouched("selectedTreatmentOptions");
                                        store.setSelectedTreatmentOptions(event);
                                        handleValueChange(event,
                                            "selectedTreatmentOptions",
                                            store.selectedTreatmentOptions,
                                            savedState.selectedTreatmentOptions);
                                        handleUpdateTreatmentType(event)
                                    }}
                                    onBlur={(event) => {
                                        setFieldsTouched("selectedTreatmentOptions");
                                    }}
                                    isClearable={true} isDisabled={false} isSearchable={true}
                                    className={""}
                                    loadingMessage={() => { return <>Loading...</> }}
                                    options={store.treatmentNeedsOptions}></BdsMultiSelect>
                            </div>

                            <NbarTextArea
                                id="treatment-needs-description" 
                                name="treatmentNeedsDescription" 
                                value={store.treatmentNeedsDescription}
                                onChange={(event) => { store.setTreatmentNeedsDescription(event.target.value); }}
                                onBlur={(event) => {
                                    setFieldsTouched("treatmentNeedsDescription");
                                    handleValueChange(event,
                                    "treatmentNeedsDescription",
                                    store.treatmentNeedsDescription,
                                    savedState.treatmentNeedsDescription);
                                }}
                                labelText="Please explain further, if applicable:"
                            ></NbarTextArea>

                        </div>
                    </Card>
                </div>
                
                {/* PHYSICIAN INFORMATION */}
                <div className="mt-4">
                    <Card>
                        <div className="bds-section-heading mandatory">Physician Information</div>
                        <div className="row mt-3">
                            <RadioButtonGroup
                                labelText="Does the individual have a primary care physician / primary care provider?"
                                radioGroupOptions={YesNoRadioOptions}
                                name="hasPrimaryCarePhysician"
                                selectedValue={store.hasPrimaryCarePhysician}
                                onChange={(event) => {
                                    store.setHasPrimaryCarePhysician(Number(event.target.value));
                                    setFieldsTouched("hasPrimaryCarePhysician", "primaryCarePhysicianName", "medicalDiagnoses");
                                    handleValueChange(event,
                                    "hasPrimaryCarePhysician",
                                    store.hasPrimaryCarePhysician,
                                    savedState.hasPrimaryCarePhysician)
                                }}
                            ></RadioButtonGroup>

                            <ShowThen when={store.hasPrimaryCarePhysician == enums.YesNoNARadioButtonGroupOptions.Yes}>
                                <div className="col-md-6">
                                    <label className="form-check-label" htmlFor="physician-name">Physician/primary care provider name:</label>
                                    <input type="text" id="physician-name" name="primaryCarePhysicianName" value={store.primaryCarePhysicianName}
                                        onChange={(event) => { store.setPrimaryCarePhysicianName(event.target.value); }}
                                        onBlur={(event) => {
                                            setFieldsTouched("primaryCarePhysicianName");
                                            handleValueChange(event,
                                            "primaryCarePhysicianName",
                                            store.primaryCarePhysicianName,
                                            savedState.primaryCarePhysicianName);
                                        }}
                                        className="bds-form-text bds-grid-form form-control" maxLength={50} />                                    
                                </div>
                                <NbarTextArea
                                    id="medical-diagnoses" name="medicalDiagnoses" 
                                    value={store.medicalDiagnoses}
                                    onChange={(event) => { store.setMedicalDiagnoses(event.target.value); }}
                                    onBlur={(event) => {
                                        setFieldsTouched("medicalDiagnoses");
                                        handleValueChange(event,
                                            "medicalDiagnoses",
                                            store.medicalDiagnoses,
                                            savedState.medicalDiagnoses);
                                        }}
                                    labelText="Medical Diagnoses"
                                ></NbarTextArea>
                            </ShowThen>
                        </div>
                    </Card>
                </div>
                
                {/* PSYCHIATRIC INFORMATION */}
                <div className="mt-4">
                    <Card>
                        <div className="bds-section-heading mandatory">Psychiatric Information</div>
                        <div className="row mt-3">
                            <RadioButtonGroup
                                labelText="Is the individual currently under the care of a psychiatrist / psychiatric nurse practitioner?"
                                radioGroupOptions={YesNoRadioOptions}
                                name="isUnderPsychiatricCare"
                                selectedValue={store.isUnderPsychiatricCare}
                                onChange={(event) => {
                                    store.setIsUnderPsychiatricCare(Number(event.target.value))
                                    setFieldsTouched("isUnderPsychiatricCare", "psychiatristName", "psychiatristDiagnoses");
                                    handleValueChange(event,
                                        "isUnderPsychiatricCare",
                                        store.isUnderPsychiatricCare,
                                        savedState.isUnderPsychiatricCare
                                    )
                                }}
                            ></RadioButtonGroup>

                            <ShowThen when={store.isUnderPsychiatricCare == enums.YesNoNARadioButtonGroupOptions.Yes}>
                                <div className="col-12">
                                    <label className="form-check-label" htmlFor="psychiatrist-name">Psychiatrist / psychiatric nurse practitioner's name:</label>
                                    <input type="text" id="psychiatrist-name" name="psychiatristName" value={store.psychiatristName}
                                        onChange={(event) => { store.setPsychiatristName(event.target.value); }}
                                        onBlur={(event) => {
                                            setFieldsTouched("psychiatristName");
                                            handleValueChange(event,
                                            "psychiatristName",
                                            store.psychiatristName,
                                            savedState.psychiatristName);
                                        }}
                                        className="bds-form-text bds-grid-form form-control" maxLength={50} 
                                    />
                                    <NbarTextArea
                                        id="psychiatrist-diagnoses" 
                                        name="psychiatristDiagnoses" 
                                        value={store.psychiatristDiagnoses}
                                        onChange={(event) => { 
                                            store.setPsychiatristDiagnoses(event.target.value);
                                        }}
                                        onBlur={(event) => {
                                            setFieldsTouched("psychiatristDiagnoses");
                                            handleValueChange(event,
                                                "psychiatristDiagnoses",
                                                store.psychiatristDiagnoses,
                                                savedState.psychiatristDiagnoses
                                            )
                                        }}
                                        labelText="Psychiatrist Diagnoses"
                                    ></NbarTextArea>                                   
                                </div>
                            </ShowThen>
                        </div>
                    </Card>
                </div>
                
                {/* CURRENT MEDICATION INFORMATION*/}
                <div className="mt-4">
                    <Card>
                        <div className="bds-section-heading mandatory">Current Medication Information</div>
                        <div className="row mt-3">
                            <RadioButtonGroup
                                labelText="Is the individual currently prescribed medications?"
                                radioGroupOptions={YesNoRadioOptions}
                                name="hasMedications"
                                selectedValue={store.hasMedications}
                                onChange={(event) => {
                                    checkClearAllMedications(event);
                                    setFieldsTouched("hasMedications")                                  
                                }}
                            ></RadioButtonGroup>
                        </div>

                        <ShowThen when={store.hasMedications == enums.YesNoNARadioButtonGroupOptions.Yes}>
                            <div className="row mt-3">
                                <div className="bds-section-details mt-2">
                                    Please provide a list of current medications and reasons they have been prescribed, if applicable. 
                                </div>
                                <div className="row mt-3">
                                    <ShowThen when={medicationErrors != ''}>
                                        <label className="bds-error">{medicationErrors}</label>
                                    </ShowThen>
                                    <ul className="list-group">
                                        
                                        <li className="list-group-item">
                                            <div className="row">
                                                <div className="col-md-4 mt-3">
                                                    <input type="text" className="form-control" id="medication-name"
                                                        value={store.addMedicationName}
                                                        placeholder="Medication Name"
                                                        onChange={(event) => { store.setAddMedicationName(event.target.value); }}
                                                        onBlur={(event) => {
                                                        setFieldsTouched("addMedicationName");
                                                        handleValueChange(event,
                                                            "addMedicationName",
                                                            store.addMedicationName,
                                                            savedState.addMedicationName)
                                                        }}
                                                    />
                                                </div>

                                                <div className="col-md-6 mt-3">
                                                    <textarea id="add-medication-description"
                                                        placeholder="Reason for prescription"
                                                        name="addMedicationDescription" 
                                                        value={store.addMedicationDescription}
                                                        onChange={(event) => { store.setAddMedicationDescription(event.target.value); }}
                                                        onBlur={(event) => {
                                                            setFieldsTouched("addMedicationDescription");
                                                            handleValueChange(event,
                                                            "addMedicationDescription",
                                                            store.addMedicationDescription,
                                                            savedState.addMedicationDescription);
                                                        }}
                                                        className="bds-textarea form-control" rows={1} maxLength={1000} />
                                                </div>

                                                <div className="col-md-2 mt-3">
                                                    <button className="btn bds-btn-primary" onClick={(event) => handleAddMedication(event)}>Add</button>
                                                </div>
                                            </div>                               
                                        </li>
                                    </ul>
                                </div>
                                
                                <div className="row mt-3">
                                    <ul className="list-group">                              
                                    {
                                        medicationList.map((medication, index) => {
                                            return (
                                                <li className="list-group-item" key={`medication_${index}`}>
                                                    <div className="row">
                                                        <div className="col-md-4"><Form.Label>{medication.medicationName}</Form.Label></div>
                                                        <div className="col-md-6"><Form.Label>{medication.prescriptionReason}</Form.Label></div>
                                                        <div className="col-md-2">
                                                            <button className="btn bds-btn-primary" name={medication.medicationInformationId?.toString()} onClick={(event) => handleRemoveMedication(event)}>Remove</button>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                    </ul>
                                </div>
                            </div>

                            <ShowThen when={medicationList.length > 0 }>
                                <div className="row mt-3">
                                    <RadioButtonGroup
                                        labelText="Has the individual experienced any changes in Medication?"
                                        radioGroupOptions={YesNoRadioOptions}
                                        name="hasChangesInMedication"
                                        selectedValue={store.hasChangesInMedication}
                                        onChange={(event) => {
                                            store.setHasChangesInMedication(Number(event.target.value))
                                            checkClearAllMedicationFrequencies(event);
                                            setFieldsTouched("hasChangesInMedication")
                                            handleValueChange(event, 
                                                "hasChangesInMedication", 
                                                store.hasChangesInMedication, 
                                                savedState.hasChangesInMedication
                                            )
                                        }}
                                    ></RadioButtonGroup>

                                    <ShowThen when={store.hasChangesInMedication == enums.YesNoNARadioButtonGroupOptions.Yes}>
                                        <div className="row mt-3">
                                            <ShowThen when={medicationChangeErrors != ''}>
                                                <label className="bds-error">{medicationChangeErrors}</label>
                                            </ShowThen>
                                            <ul className="list-group">
                                                <li className="list-group-item">
                                                    <div className="row">
                                                        <div className="col-md-5 mt-3">
                                                            <select name="selectedMedicationForChange" value={store.selectedMedicationForChange}
                                                                onChange={(event) => {
                                                                    store.setSelectedMedicationForChange(event.target.value)
                                                                }}
                                                                onBlur={(event) => {
                                                                    // setFieldsTouched("addMedicationLastChanged");
                                                                    // handleValueChange(event,
                                                                    // "addMedicationLastChanged",
                                                                    // store.addMedicationLastChanged,
                                                                    // savedState.addMedicationLastChanged);
                                                                }}
                                                                className="bds-form-select bds-grid-form form-select">
                                                                <option value="">Which One?</option>
                                                                {
                                                                    medicationList.filter(o => o.prescriptionChangeTypeId == null).map((item, index) => (
                                                                        <option key={`medicationOption_${index}`} value={item.medicationInformationId}>{item.medicationName}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>                              
                                                        <div className="col-md-5 mt-3">
                                                            <select name="addMedicationLastChanged" value={store.addMedicationLastChanged}
                                                                onChange={(event) => {
                                                                    store.setAddMedicationLastChanged(event.target.value);
                                                                }}
                                                                onBlur={(event) => {
                                                                    setFieldsTouched("addMedicationLastChanged");
                                                                    handleValueChange(event,
                                                                    "addMedicationLastChanged",
                                                                    store.addMedicationLastChanged,
                                                                    savedState.addMedicationLastChanged);
                                                                }}
                                                                className="bds-form-select bds-grid-form form-select">
                                                                <option value="">When?</option>
                                                                {
                                                                    store.medicationChangeIntervalOptions.map((item, index) => (
                                                                    <option key={`frequencyOption_${index}`} value={item.value}>{item.label}</option>
                                                                    ))
                                                                }
                                                            </select>                                           
                                                        </div>
                                                        <div className="col-md-2 mt-3">
                                                            <button className="btn bds-btn-primary" onClick={(event) => handleFrequencyUpdate(event)}>Update</button>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="row mt-3">
                                            <ul className="list-group">                          
                                                {
                                                    medicationList.filter((o) => o.prescriptionChangeTypeId > 0).map(medication => {
                                                        return (
                                                            <li className="list-group-item" key={`${medication.medicationInformationId}_freq`}>
                                                                <div className="row">
                                                                    <div className="col-md-5">{medication.medicationName}</div>
                                                                    <div className="col-md-5">{store.medicationChangeIntervalOptions.find(i => Number(i.value) == medication.prescriptionChangeTypeId).label}</div>
                                                                    <div className="col-md-2"><button name={medication.medicationInformationId.toString()} className="btn bds-btn-primary" onClick={(event) => handleRemoveInterval(event) }>Remove</button></div>
                                                                </div>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </ShowThen>

                                </div>
                            </ShowThen>

                        </ShowThen>

                        <div className="row mt-3">
                            <RadioButtonGroup
                                labelText="Does the individual refuse to take medications?"
                                radioGroupOptions={YesNoRadioOptions}
                                name="hasRefusedMedication"
                                selectedValue={store.hasRefusedMedication}
                                onChange={(event) => {
                                    store.setHasRefusedMedication(Number(event.target.value))
                                    setFieldsTouched("hasRefusedMedication")
                                    handleValueChange(event, 
                                        "hasRefusedMedication", 
                                        store.hasRefusedMedication, 
                                        savedState.hasRefusedMedication
                                    )
                                }}
                            ></RadioButtonGroup>

                            <ShowThen when={store.hasRefusedMedication == enums.YesNoNARadioButtonGroupOptions.Yes}>
                                <NbarTextArea
                                    id="has-refused-medication-description" 
                                    name="hasRefusedMedicationDescription" 
                                    value={store.hasRefusedMedicationDescription}
                                    onChange={(event) => { 
                                        store.setHasRefusedMedicationDescription(event.target.value);
                                    }}
                                    onBlur={(event) => {
                                        setFieldsTouched("hasRefusedMedicationDescription");
                                        handleValueChange(event,
                                            "hasRefusedMedicationDescription",
                                            store.hasRefusedMedicationDescription,
                                            savedState.hasRefusedMedicationDescription
                                        )
                                    }}
                                    labelText="Describe how often and how you ensure medications are administered:"
                                ></NbarTextArea>
                            </ShowThen>
                        </div>

                    </Card>
                </div>
                
                {/* BEHAVIOR MANAGEMENT*/}
                <div className="mt-4">
                    <Card>
                        <div className="bds-section-heading mandatory">Behavior Management</div>
                        <div className="row mt-3">
                            <RadioButtonGroup
                                labelText="Is the individual currently receiving behavior management services?"
                                radioGroupOptions={YesNoRadioOptions}
                                name="hasBehaviorManagmentService"
                                selectedValue={store.hasBehaviorManagmentService}
                                onChange={(event) => {
                                    store.setHasBehaviorManagmentService(Number(event.target.value))
                                    setFieldsTouched("hasBehaviorManagmentService");
                                    handleValueChange(event,
                                        "hasBehaviorManagmentService",
                                        store.hasBehaviorManagmentService,
                                        savedState.hasBehaviorManagmentService
                                    )
                                    
                                }}
                            ></RadioButtonGroup>
                        </div>
                        <div className="row mt-3">
                            <RadioButtonGroup
                                labelText="Has the individual received a consultation or services for behavior management in the past?"
                                radioGroupOptions={YesNoRadioOptions}
                                name="hasBehaviorManagmentConsultation"
                                selectedValue={store.hasBehaviorManagmentConsultation}
                                onChange={(event) => {
                                    store.setHasBehaviorManagmentConsultation(Number(event.target.value))
                                    setFieldsTouched("hasBehaviorManagmentConsultation");
                                    handleValueChange(event,
                                        "hasBehaviorManagmentConsultation",
                                        store.hasBehaviorManagmentConsultation,
                                        savedState.hasBehaviorManagmentConsultation
                                    )
                                }}
                            ></RadioButtonGroup>
                        </div>

                        <div className="row mt-3">
                            <RadioButtonGroup
                                labelText="Has the individual experienced any recent events that may trigger behaviors?
                                    Examples could include death of a friend or relative, change in environment, moving to a new residence."
                                radioGroupOptions={YesNoRadioOptions}
                                name="hasTriggerEvents"
                                selectedValue={store.hasTriggerEvents}
                                onChange={(event) => {
                                    store.setHasTriggerEvents(Number(event.target.value))
                                    setFieldsTouched("hasTriggerEvents", "triggerEventsDescription");
                                    handleValueChange(event,
                                        "hasTriggerEvents",
                                        store.hasTriggerEvents,
                                        savedState.hasTriggerEvents
                                    )
                                }}
                            ></RadioButtonGroup>

                            <ShowThen when={store.hasTriggerEvents == enums.YesNoNARadioButtonGroupOptions.Yes}>
                                <NbarTextArea
                                    id="trigger-events-description" 
                                    name="triggerEventsDescription" 
                                    value={store.triggerEventsDescription}
                                    onChange={(event) => { 
                                        store.setTriggerEventsDescription(event.target.value);
                                    }}
                                    onBlur={(event) => {
                                        setFieldsTouched("triggerEventsDescription");
                                        handleValueChange(event,
                                            "triggerEventsDescription",
                                            store.triggerEventsDescription,
                                            savedState.triggerEventsDescription
                                        )
                                    }}
                                    labelText="Please Describe:"
                                ></NbarTextArea>
                            </ShowThen>
                        </div>

                        <div className="row mt-3">
                            <RadioButtonGroup
                                labelText="Is the individual receiving additional services?"
                                radioGroupOptions={YesNoRadioOptions}
                                name="hasAdditionalServices"
                                selectedValue={store.hasAdditionalServices}
                                onChange={(event) => {
                                    store.setHasAdditionalServices(Number(event.target.value))
                                    setFieldsTouched("hasAdditionalServices", "selectedAdditionalServiceOptions");
                                    handleValueChange(event,
                                        "hasAdditionalServices",
                                        store.hasAdditionalServices,
                                        savedState.hasAdditionalServices
                                    )
                                    checkClearAllAdditionalServiceOptions(event.target.value)
                                }}
                            ></RadioButtonGroup>

                            <ShowThen when={store.hasAdditionalServices == enums.YesNoNARadioButtonGroupOptions.Yes}>
                                <div className="row mt-3">
                                    <div className="col-md-6">
                                        <label className="form-check-label" htmlFor="additional-service-list">Please select all that apply:</label>
                                        <BdsMultiSelect name="selectedAdditionalServiceOptions"
                                            value={store.selectedAdditionalServiceOptions}
                                            onChange={(event) => {
                                                setFieldsTouched("selectedAdditionalServiceOptions", "additionalServicesList");
                                                store.setSelectedAdditionalServiceOptions(event);
                                                handleValueChange(event,
                                                    "selectedAdditionalServiceOptions",
                                                    store.selectedAdditionalServiceOptions,
                                                    savedState.selectedAdditionalServiceOptions);
                                                    handleUpdateAdditionalServices(event)
                                            }}
                                            onBlur={(event) => {
                                                setFieldsTouched("selectedAdditionalServiceOptions", "additionalServicesList");
                                            }}
                                            isClearable={true} isDisabled={false} isSearchable={true}
                                            className={""}
                                            loadingMessage={() => { return <>Loading...</> }}
                                            options={store.behavioralServiceOptions}></BdsMultiSelect>
                                    </div>
                                </div>
                            </ShowThen>
                        </div>
                    </Card>
                </div>
                
                {/* HOSPITALIZATION INFORMATION*/}
                <div className="mt-4">
                    <Card>
                        <div className="bds-section-heading mandatory">Hospitalization Information</div>
                        <div className="row mt-3">
                            <RadioButtonGroup
                                labelText="Has the individual experienced any recent hospitalizations?"
                                radioGroupOptions={YesNoRadioOptions}
                                name="hasRecentHospitalizations"
                                selectedValue={store.hasRecentHospitalizations}
                                onChange={(event) => {
                                    store.setHasRecentHospitalizations(Number(event.target.value))
                                    setFieldsTouched("hasRecentHospitalizations", "recentHospitalizationDescription")
                                    handleValueChange(event, 
                                        "hasRecentHospitalizations", 
                                        store.hasRecentHospitalizations, 
                                        savedState.hasRecentHospitalizations
                                    )
                                }}
                            ></RadioButtonGroup>

                            <ShowThen when={store.hasRecentHospitalizations == enums.YesNoNARadioButtonGroupOptions.Yes}>
                                <NbarTextArea
                                    id="hospitalizations-description" 
                                    name="recentHospitalizationDescription" 
                                    value={store.recentHospitalizationDescription}
                                    onChange={(event) => { 
                                        store.setRecentHospitalizationDescription(event.target.value);
                                    }}
                                    onBlur={(event) => {
                                        setFieldsTouched("recentHospitalizationDescription");
                                        handleValueChange(event,
                                            "recentHospitalizationDescription",
                                            store.recentHospitalizationDescription,
                                            savedState.recentHospitalizationDescription
                                        )
                                    }}
                                    labelText="Please Describe:"
                                ></NbarTextArea>
                            </ShowThen>
                        </div>
                    </Card>
                </div>
                
                {/* LAW ENFORCEMENT INFORMATION*/}
                <div className="mt-4">
                    <Card>
                        <div className="bds-section-heading mandatory">Law Enforcement Information</div>
                        <div className="row mt-3">
                            <RadioButtonGroup
                                labelText="Has there been law enforcement intervention for aggression or elopement?"
                                radioGroupOptions={YesNoRadioOptions}
                                name="hasPastLawEnforcement"
                                selectedValue={store.hasPastLawEnforcement}
                                onChange={(event) => {
                                    store.setHasPastLawEnforcement(Number(event.target.value))
                                    setFieldsTouched("hasPastLawEnforcement", "pastLawEnforcementDescription")
                                    handleValueChange(event, 
                                        "hasPastLawEnforcement", 
                                        store.hasPastLawEnforcement, 
                                        savedState.hasPastLawEnforcement
                                    )
                                }}
                            ></RadioButtonGroup>

                            <ShowThen when={store.hasPastLawEnforcement == enums.YesNoNARadioButtonGroupOptions.Yes}>
                                <NbarTextArea
                                    id="law-enforcement-description" 
                                    name="pastLawEnforcementDescription" 
                                    value={store.pastLawEnforcementDescription}
                                    onChange={(event) => { 
                                        store.setPastLawEnforcementDescription(event.target.value);
                                    }}
                                    onBlur={(event) => {
                                        setFieldsTouched("pastLawEnforcementDescription");
                                        handleValueChange(event,
                                            "pastLawEnforcementDescription",
                                            store.pastLawEnforcementDescription,
                                            savedState.pastLawEnforcementDescription
                                        )
                                    }}
                                    labelText="Please Describe:"
                                ></NbarTextArea>
                            </ShowThen>

                        </div>
                    </Card>
                </div>
                
                {/* DAILY LIFE INFORMATION*/}
                <div className="mt-4">
                    <Card>
                        <div className="bds-section-heading mandatory">Daily Life Information</div>
                        <div className="row mt-3">
                            <RadioButtonGroup
                                labelText="Does the individual and family stick to a structured schedule or routine at home?"
                                radioGroupOptions={YesNoRadioOptions}
                                name="hasStructuredRoutine"
                                selectedValue={store.hasStructuredRoutine}
                                onChange={(event) => {
                                    store.setHasStructuredRoutine(Number(event.target.value))
                                    setFieldsTouched("hasStructuredRoutine")
                                    handleValueChange(event, 
                                        "hasStructuredRoutine", 
                                        store.hasStructuredRoutine, 
                                        savedState.hasStructuredRoutine
                                    )
                                }}
                            ></RadioButtonGroup>

                            <ShowThen when={store.hasStructuredRoutine == enums.YesNoNARadioButtonGroupOptions.Yes}>
                                <NbarTextArea
                                    id="routine-description" 
                                    name="routineDescription" 
                                    value={store.routineDescription}
                                    onChange={(event) => { 
                                        store.setRoutineDescription(event.target.value);
                                    }}
                                    onBlur={(event) => {
                                        setFieldsTouched("routineDescription");
                                        handleValueChange(event,
                                            "routineDescription",
                                            store.routineDescription,
                                            savedState.routineDescription
                                        )
                                    }}
                                    labelText="Please Describe:"
                                ></NbarTextArea>
                            </ShowThen>
                        </div>

                        <div className="row mt-3">
                            <RadioButtonGroup
                                labelText="Does the individual participate in community activities?"
                                radioGroupOptions={YesNoRadioOptions}
                                name="participatesInCommunityActivities"
                                selectedValue={store.participatesInCommunityActivities}
                                onChange={(event) => {
                                    store.setParticipatesInCommunityActivities(Number(event.target.value))
                                    setFieldsTouched("participatesInCommunityActivities", "communityActivityDescription")
                                    handleValueChange(event, 
                                        "participatesInCommunityActivities", 
                                        store.participatesInCommunityActivities, 
                                        savedState.participatesInCommunityActivities
                                    )
                                }}
                            ></RadioButtonGroup>

                            <ShowThen when={store.participatesInCommunityActivities == enums.YesNoNARadioButtonGroupOptions.Yes}>
                                <NbarTextArea
                                    id="community-activity-description" 
                                    name="communityActivityDescription" 
                                    value={store.communityActivityDescription}
                                    onChange={(event) => { 
                                        store.setCommunityActivityDescription(event.target.value);
                                    }}
                                    onBlur={(event) => {
                                        setFieldsTouched("communityActivityDescription");
                                        handleValueChange(event,
                                            "communityActivityDescription",
                                            store.communityActivityDescription,
                                            savedState.communityActivityDescription
                                        )
                                    }}
                                    labelText="Please Describe:"
                                ></NbarTextArea>
                            </ShowThen>
                        </div>

                        <div className="row mt-3">
                            <div className="col-12">
                                <label className="form-check-label" htmlFor="routine-description"><strong>What does a meaningful day look like for the individual?</strong> </label>
                                <NbarTextArea
                                    id="meaningful-day-description" 
                                    name="meaningfulDayDescription" 
                                    value={store.meaningfulDayDescription}
                                    onChange={(event) => { 
                                        store.setMeaningfulDayDescription(event.target.value);
                                    }}
                                    onBlur={(event) => {
                                        setFieldsTouched("meaningfulDayDescription");
                                        handleValueChange(event,
                                            "meaningfulDayDescription",
                                            store.meaningfulDayDescription,
                                            savedState.meaningfulDayDescription
                                        )
                                    }}
                                    labelText="Examples may include job opportunities, VR services, hobbies/interests, services that occur throughout the day."
                                ></NbarTextArea>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-12">
                                <label className="form-check-label" htmlFor="capacity-of-waiver-usage"><strong>In what capacity would the waiver be used?</strong></label>
                                <NbarTextArea
                                    id="capacity-of-waiver-usage" 
                                    name="capacityOfWaiverUsage" 
                                    value={store.capacityOfWaiverUsage}
                                    onChange={(event) => { 
                                        store.setCapacityOfWaiverUsage(event.target.value);
                                    }}
                                    onBlur={(event) => {
                                        setFieldsTouched("capacityOfWaiverUsage");
                                        handleValueChange(event,
                                            "capacityOfWaiverUsage",
                                            store.capacityOfWaiverUsage,
                                            savedState.capacityOfWaiverUsage
                                        )
                                    }}
                                    labelText="Examples may include Behavior Management, Therapy, Residential Care, Respite Services."
                                ></NbarTextArea>                                       
                            </div>
                        </div>

                    </Card>
                </div>
                
                {/* EDUCATIONAL INFORMATION*/}
                <div className="mt-4">
                    <Card>
                        <div className="bds-section-heading mandatory">Educational Information</div>
                        <div className="row mt-3">
                            <RadioButtonGroup
                                labelText="Is the individual currently a student?"
                                radioGroupOptions={YesNoNARadioOptions}
                                name="isStudent"
                                selectedValue={store.isStudent}
                                onChange={(event) => {
                                    store.setIsStudent(Number(event.target.value))
                                    setFieldsTouched("isStudent",
                                        "studentStatus",
                                        "studentWeeklySchedule",
                                        "hasIEP",
                                        "isInSpecialEd",
                                        "hasSchoolBehaviors",
                                        "schoolBehaviorFrequency",
                                        "schoolBehaviorDescription"
                                    )
                                    handleValueChange(event, 
                                        "isStudent", 
                                        store.isStudent, 
                                        savedState.isStudent
                                    )
                                }}
                            ></RadioButtonGroup>

                            <ShowThen when={store.isStudent == enums.YesNoNARadioButtonGroupOptions.Yes}>

                                    <div className="row mt-3">
                                        <RadioButtonGroup
                                            labelText="Does the individual attend full or part time?"
                                            radioGroupOptions={StudentStatusRadioOptions}
                                            name="studentStatus"
                                            selectedValue={store.studentStatus}
                                            onChange={(event) => {
                                                store.setStudentStatus(Number(event.target.value))
                                                setFieldsTouched("studentStatus", 
                                                "studentWeeklySchedule"
                                                )
                                                handleValueChange(event,
                                                    "studentStatus",
                                                    store.studentStatus,
                                                    savedState.studentStatus
                                                )
                                            }}
                                        ></RadioButtonGroup>
                                    </div>

                                    <ShowThen when={store.studentStatus == enums.StudentStatusRadioButtonGroupOptions.PartTime}>
                                        <NbarTextArea
                                            id="student-schedule" 
                                            name="studentWeeklySchedule" 
                                            value={store.studentWeeklySchedule}
                                            onChange={(event) => { 
                                                store.setStudentWeeklySchedule(event.target.value);
                                            }}
                                            onBlur={(event) => {
                                                setFieldsTouched("studentWeeklySchedule");
                                                handleValueChange(event,
                                                    "studentWeeklySchedule",
                                                    store.studentWeeklySchedule,
                                                    savedState.studentWeeklySchedule
                                                )
                                            }}
                                            labelText="Please specify how may days a week and how many hours the individual attends school?"
                                        ></NbarTextArea>
                                    </ShowThen>

                                    <div className="row mt-3">
                                        <RadioButtonGroup
                                            labelText="Does the individual have an IEP?"
                                            radioGroupOptions={YesNoRadioOptions}
                                            name="hasIEP"
                                            selectedValue={store.hasIEP}
                                            onChange={(event) => {
                                                store.setHasIEP(Number(event.target.value))
                                                setFieldsTouched("hasIEP");
                                                handleValueChange(event,
                                                    "hasIEP",
                                                    store.hasIEP,
                                                    savedState.hasIEP
                                                )
                                            }}
                                        ></RadioButtonGroup>
                                    </div>

                                    <div className="row mt-3">
                                        <RadioButtonGroup
                                            labelText="Is the individual in special education classes?"
                                            radioGroupOptions={YesNoRadioOptions}
                                            name="isInSpecialEd"
                                            selectedValue={store.isInSpecialEd}
                                            onChange={(event) => {
                                                store.setIsInSpecialEd(Number(event.target.value))
                                                setFieldsTouched("isInSpecialEd");
                                                handleValueChange(event,
                                                    "isInSpecialEd",
                                                    store.isInSpecialEd,
                                                    savedState.isInSpecialEd
                                                )
                                            }}
                                        ></RadioButtonGroup>
                                    </div>

                                    <div className="row mt-3">
                                        <RadioButtonGroup
                                            labelText="Does the individual have behaviors at school?"
                                            radioGroupOptions={YesNoNARadioOptions}
                                            name="hasSchoolBehaviors"
                                            selectedValue={store.hasSchoolBehaviors}
                                            onChange={(event) => {
                                                store.setHasSchoolBehaviors(Number(event.target.value))
                                                setFieldsTouched("hasSchoolBehaviors", 
                                                    "schoolBehaviorFrequency",
                                                    "schoolBehaviorDescription"
                                                )
                                                handleValueChange(event,
                                                    "hasSchoolBehaviors",
                                                    store.hasSchoolBehaviors,
                                                    savedState.hasSchoolBehaviors
                                                )
                                            }}
                                        ></RadioButtonGroup>
                                    </div>

                                    <ShowThen when={store.hasSchoolBehaviors == enums.YesNoNARadioButtonGroupOptions.Yes}>
                                        <div className="col-12 mt-3">
                                            <label className="bds-form-label form-label">Please indicate the frequency of the behaviors:</label>
                                            <select name="agency" value={store.schoolBehaviorFrequency}
                                                onChange={(event) => {
                                                    store.setSchoolBehaviorFrequency(Number(event.target.value));
                                                }}
                                                onBlur={(event) => {
                                                    setFieldsTouched("schoolBehaviorFrequency");
                                                    handleValueChange(event,
                                                    "schoolBehaviorFrequency",
                                                    store.schoolBehaviorFrequency,
                                                    savedState.schoolBehaviorFrequency);
                                                }}
                                                className="bds-form-select bds-grid-form form-select">
                                                <option value="">Please Select ...</option>
                                                {
                                                    store.schoolBehaviorFrequencyOptions.map((item, index) => (
                                                        <option key={index} value={item.value}>{item.label}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <div className="row mt-3">
                                            <NbarTextArea
                                                id="school-behaviors" name="schoolBehaviorDescription" 
                                                value={store.schoolBehaviorDescription}
                                                onChange={(event) => { store.setSchoolBehaviorDescription(event.target.value); }}
                                                onBlur={(event) => {
                                                    setFieldsTouched("schoolBehaviorDescription");
                                                    handleValueChange(event,
                                                        "schoolBehaviorDescription",
                                                        store.schoolBehaviorDescription,
                                                        savedState.schoolBehaviorDescription);
                                                    }}
                                                labelText="Please describe the behaviors that occur at school:"
                                            ></NbarTextArea>
                                        </div>
                                    </ShowThen>

                            </ShowThen>
                        </div>
                    </Card>
                </div>               
                
                {/* BUTTONS */}
                <div className="row mt-4">
                    <div className="col-12 col-md-6 order-1 order-md-0 mt-3 mt-md-0">
                        <button type="button" className="btn bds-btn-primary" disabled={isLoading}
                        onClick={() => NavigationHelper.gotoNbarDemographics(navigate, nbarApplicationRefId)}>Back</button>
                        <IsAuthenticated>
                            <button type="button" className="btn bds-btn-primary ms-0 ms-md-3 mt-3 mt-md-0"
                                onClick={() => NavigationHelper.gotoDashboard(navigate)}>Save for Later</button>
                        </IsAuthenticated>
                    </div>
                    <div className="col-12 col-md-6 order-0 order-md-1 text-start text-md-end">
                        <button type="button" disabled={isLoading} onClick={(event) => handleGoToNextStep(event)}
                        className="btn bds-btn-primary">Next</button>
                    </div>
                </div>


            </form>
        </>
    )
}

export default observer(TreatmentInformation)