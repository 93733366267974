import { AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { HttpRequestInterceptor } from "../api-resources/api.base.resource";
import { EventBroadcasterService } from "../events/event-broadcaster.service";
import { GatewayError, GatewayErrorCodes, GatewayNetworkError, ApplicationExpirationError } from "shared-module/models/common.models";

export class GatewayOapApiRequestInterceptor implements HttpRequestInterceptor {
  constructor() {
  }

  public InterceptRequest(config: InternalAxiosRequestConfig)
    : InternalAxiosRequestConfig | Promise<InternalAxiosRequestConfig> {
    /* Add code here */
    return config;
  }

  public InterceptRequestError(error: any): any {
    /* Add code here */
    return Promise.reject(error);
  }

  public InterceptResponse(response: AxiosResponse)
    : AxiosResponse | Promise<AxiosResponse> {

    EventBroadcasterService.RaiseClearServerResponseErrorEvent();
    this.handleApplicationSessionExpired(response);

    if (response.status >= 300 && response.status < 400) {
      console.log('Status Codes: [300, 399]:', response);
      return {} as any;
    }

    if (response.status >= 400 && response.status < 500) {
        console.log('Status Codes: [400, 401[:', response);
        if(response.status == 410) {
          EventBroadcasterService.RaiseApplicationSessionExpiredEvent();
          return Promise.reject(new GatewayNetworkError());
        }
        throw response.data;
    }

    return response;

  }

  public InterceptResponseError(error: any): any {
    console.log('InterceptResponseError: ', error);
    if (error.code && error.code == GatewayErrorCodes.NETWORK_ERROR) {     
      EventBroadcasterService.RaiseServerResponseErrorEvent();
      return Promise.reject(new GatewayNetworkError());
    }
    console.log("GATEWAY RESPONSE ERROR: ", error);
    return Promise.reject(error);
  }

  private handleApplicationSessionExpired(response: AxiosResponse) {
    if (response.status == 410) {
      throw new ApplicationExpirationError(response.data);
    }
  }
}