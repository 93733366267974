import { useNavigate } from "react-router-dom";
import { NavigationHelper } from "../../../../shared-module/helpers/navigation-helper";

function AccessIndianaNbarCardComponent() {
  const navigate = useNavigate();

  function handleLogin() {
    NavigationHelper.gotoNbarAuthorization(navigate);
  }

  function handleCreateAccount() {
    NavigationHelper.gotoRegisteration(navigate);
  }

  return (
    <div className="access-indiana-card my-4">
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="feature-panel pe-0 pe-md-3">
            <div className="panel-cell">
              <span className="heading">Apply using Access Indiana</span>
              <ul className="mt-2">
                <li>Easily and securely access your application</li>
                <li>Save and return to an application in-progress</li>
                <li>Upload, download, and view documentation</li>
                <li>Invite another user to assist with your application</li>
              </ul>
            </div>
            <div className="row mt-0 mt-lg-3">
              <div className="col-12 col-lg-6">
                <button type="button" onClick={handleLogin}
                  className="bds-aa-button btn bds-btn-primary">Login</button>
              </div>
              <div className="col-12 col-lg-6 mt-3 mt-lg-0">
                <button type="button" onClick={handleCreateAccount}
                  className="bds-aa-button btn bds-btn-primary">Create an Account</button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-11 col-md-6 mt-4 mt-md-0 ps-0 ps-md-3">
          <div className="feature-panel">
            <div className="panel-cell">
              <span className="heading">Apply as a Guest</span>
              <ul className="mt-2">
                <li>Inability to save and return to application</li>
                <li>Inability to upload documents</li>
                <li>Inactivity for 15 minutes will require the application to be restarted</li>
                <li>Application must be completed at one time</li>
              </ul>
              <div>
                <em>
                  Proceed without using Access Indiana
                </em>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export const AccessIndianaNbarCard = AccessIndianaNbarCardComponent;