import { useEffect } from "react";
import { MainHeader } from "./main-header/main-header.component";
import { MainSubHeader } from "./main-sub-header/main-sub-header.component";
import { Outlet, useLocation } from "react-router-dom";
import { DevelopmentBanner } from "../../../shared-module/components/development-banner/development-banner.component";
import { UnderMaintenanceBanner } from "../under-maintenance/under-maintenance.component";
import { ShowThen } from "../../../shared-module/components/show-then/show-then.component";
import { AppSettings } from "../../../app-setting";
import { AutoExpireComponent } from "../auto-expire-notification/auto-expire-notification-component";
import { InternalErrorBanner } from "../internal-server-error/internal-server-error-component";
import { AutoExpireGuestComponent } from "../auto-expire-notification/auto-expire-notfication-guest.component";

function LayoutComponent() {
  const location = useLocation();

  useEffect(() => {
    scrollTo({ top: 0, left: 0, behavior: "instant" as ScrollBehavior });
  }, [location]);

  return (
    <div>
      <InternalErrorBanner></InternalErrorBanner>
      <DevelopmentBanner></DevelopmentBanner>
      <UnderMaintenanceBanner></UnderMaintenanceBanner>
      <AutoExpireComponent></AutoExpireComponent>
      <AutoExpireGuestComponent></AutoExpireGuestComponent>
      <MainHeader></MainHeader>
      <MainSubHeader></MainSubHeader>
      <div className="container bds-container">
        <Outlet></Outlet>
      </div>
      <div className="page-footer mt-4">
        <div className="footer-contents container bds-container py-3">
          <div className="row">
            <div className="contact-menu col-12">
              For assistance and questions you may contact your local district
              office by visiting:&nbsp;
              <a href="http://www.in.gov/fssa/ddrs/4088.htm" className="bds-anchor" target="_blank">
                http://www.in.gov/fssa/ddrs/4088.htm
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export const Layout = LayoutComponent;
