import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Card } from "../../../shared-module/components/card/card.component";
import { ShowThen } from "../../../shared-module/components/show-then/show-then.component";
import { NavigationHelper } from "../../../shared-module/helpers/navigation-helper";
import { PageTitleSetter } from "../../extensions/page-title-setter/page-title-setter.component";
import { ApplicationProgressBar } from "../../extensions/application-progress-bar/application-progress-bar.component";
import { GroupButtons } from "../../../shared-module/components/group-buttons/group-buttons.component";
import { LookupResource } from "../../../shared-module/api-resources/lookup.resource";
import { ApplicationResource } from "../../../shared-module/api-resources/application.resource";
import { GatewayOapApi } from "../../../shared-module/models/gateway-oap-api.models";
import { Utility } from "../../../shared-module/helpers/utility";
import { ApplicationInformationStore, IApplicationInformation } from "./application-information.store";
import { useFormValidation } from "../../../shared-module/hooks/use-form-validation/use-form-validation.hook";
import { ErrorContainer } from "../../../shared-module/components/error-container/error-container.component";
import { DebugContainer } from "../../../shared-module/components/debug-container/debug-container.component";
import { ApplicationInformationValidationSchema } from "./application-information.validation";
import { ApiConfig } from "shared-module/services/api-config";
import { IsAuthenticated } from "shared-module/components/is-authenticated/is-authenticated";
import { ApplicationExpirationError, GatewayNetworkError } from "shared-module/models/common.models";
import BdsCheckbox from "shared-module/components/checkbox/bds-checkbox.component";
import BdsDatePicker from "shared-module/components/date-picker/bds-date-picker.component";
import { SessionService } from "shared-module/services/session.service";
import { EventBroadcasterService } from "shared-module/events/event-broadcaster.service";

function ApplicationInformation() {
  const navigate = useNavigate();
  const { id: applicationRefId } = useParams();

  const lookupService = new LookupResource(ApiConfig);
  const applicationService = new ApplicationResource(ApiConfig);

  const [entryRelationshipTypes, setEntryRelationshipTypes] = useState<GatewayOapApi.EntryRelationshipTypeModel[]>([]);
  const [suffixTypes, setSuffixTypes] = useState<GatewayOapApi.SuffixTypeModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [fromSummary, setFromSummary] = useState<boolean>(false);
  const [savedState, setSavedState] = useState<IApplicationInformation>({} as IApplicationInformation);
  const store = ApplicationInformationStore;

  const { errors, touched, setIsSubmitting, getFieldState, setFieldsTouched, touchedAll } =
    useFormValidation(ApplicationInformationValidationSchema, store);

  useEffect(() => {
    if (!hasApplicationRefId()) {
      NavigationHelper.gotoHome(navigate);
      return;
    }

    setIsLoading(true);
    Utility.showLoadingOverlay();

    Promise.all([
      lookupService.getEntryRelationshipTypes(),
      lookupService.getSuffixTypes(),
      applicationService.getApplication(applicationRefId),
    ])
      .then((response) => {
        if(!response) { return false; }
        setEntryRelationshipTypes(response[0].records);
        setSuffixTypes(response[1].records);
        store.refresh(response[2].record);
        setSavedState(store.buildState(response[2].record));
        setFromSummary(sessionStorage.getItem('fromSummary') == 'true' || false);
        return true;
      })
      .catch((ex) => { 
        if(ex instanceof ApplicationExpirationError) {
          Utility.alert("Application Timeout", ex.errorMessage);
        }
        if (!(ex instanceof GatewayNetworkError)) {
          Utility.alert("Error Saving", ex.errorMessage);
        }
        NavigationHelper.gotoHome(navigate);
      })
      .finally(() => {
        setIsLoading(false);
        Utility.hideLoadingOverlay();
      });

  }, []);

  function hasApplicationRefId() {
    return !!applicationRefId;
  }

  function handleValueChange<T>(event, eventSource: string, currentValue: T, savedValue: T) {
    if (currentValue === savedValue) {
      return;
    }

    setIsSaving(true);
    saveApplicationInformation(eventSource)
      .finally(() => {
        setIsSaving(false);
      });
  }

  function handleReturnToSummary(){
    touchedAll();
    if (ApplicationInformationValidationSchema.isValidSync(store)) {
      NavigationHelper.gotoApplicationSummary(navigate, applicationRefId);
      return;
    }

    scrollTo(0, 0);
  }

  function saveApplicationInformation(eventSource: string): Promise<any> {

    setIsSubmitting(true, eventSource);

    return applicationService.saveApplicationInformation(
      applicationRefId,
      store.selfApplying == "applying-myself",
      Utility.stringToNumber(store.entryRelationTypeId, null),
      store.entryRelationIsBdds,
      store.noFirstName,
      store.firstName,
      store.lastName,
      store.middleName,
      Utility.stringToNumber(store.suffixTypeId, null),
      store.dateOfBirth,
      store.hasMedicaid == "has-medicaid",
      store.medicaid)
      .then((response) => {
        return applicationService.getApplication(applicationRefId);
      })
      .then((response) => {
        setSavedState(store.buildState(response.record));
        var guestSessionId = sessionStorage.getItem("guestSessionId") || "0";
        if(guestSessionId != "0") {
            SessionService.slideGuestSession(Number(guestSessionId));
            EventBroadcasterService.RaiseGuestSessionExpirationEvent({
                isAuthenticated: false,
                remainingTimeInSeconds: 900,
                hasActiveMaintenance: false
            });
        }
        return true;
      })
      .catch(ex => {
        if(ex instanceof ApplicationExpirationError) {
          Utility.alert("Application Timeout", ex.errorMessage);
        }
        if (!(ex instanceof GatewayNetworkError)) {
          Utility.alert("Error Saving", ex.errorMessage);
        }
        NavigationHelper.gotoHome(navigate);
      })
      .finally(() => {
        setIsSubmitting(false, null);
      });
  }

  function handleGoToNextStep() {
    touchedAll();
    if (ApplicationInformationValidationSchema.isValidSync(store)) {
      NavigationHelper.gotoApplicantInformation(navigate, applicationRefId);
      return;
    }

    scrollTo(0, 0);
  }

  return (
    <>
      <DebugContainer data={store} saved={savedState} touched={touched} errors={errors}></DebugContainer>
      <PageTitleSetter step={2} totalSteps={8}></PageTitleSetter>
      <div className="mt-4">
        <ApplicationProgressBar step={2} totalSteps={8}></ApplicationProgressBar>
      </div>

      <ShowThen when={!isLoading}>
        <ErrorContainer data={store} saved={savedState} touched={touched} errors={errors}></ErrorContainer>
      </ShowThen>

      <IsAuthenticated>
      <div className="mt-4">
        <div className="callout">
          <div>
          A new application draft has been created in your dashboard. Your application progress will be saved and can be returned to at a later time.
          </div>
        </div>
      </div>
      </IsAuthenticated>

      <form>
        <div>
          <div className="mt-4">
            <Card>
              <div className="bds-section-heading mandatory">Who is filling out the application?</div>
              <div className="mt-3">
                <GroupButtons name="selfApplying" value={store.selfApplying}
                  onChange={(event) => {
                    setFieldsTouched("selfApplying", "entryRelationTypeId");
                    store.setSelfApplying(event.value);
                    handleValueChange(event,
                      "selfApplying",
                      store.selfApplying,
                      savedState.selfApplying);
                  }}
                  onBlur={(e) => {
                    setFieldsTouched("selfApplying", "entryRelationTypeId");
                  }}
                  items={[
                    { itemValue: "applying-myself", content: <>I am applying for myself.</> },
                    { itemValue: "apply-for-others", content: <>I am helping someone who is applying.</> }
                  ]}>
                </GroupButtons>
              </div>
            </Card>
          </div>

          <ShowThen when={store.selfApplying == 'apply-for-others'}>
            <div className="mt-4">
              <Card>
                <div className="bds-section-heading mandatory">What is your relationship to the applicant?</div>
                <div className="bds-section-details mt-2">
                  Choose the one that best describes your relationship to the applicant.
                </div>
                <div className="row mt-3">
                  <div className="col-12">
                    <select name="entryRelationTypeId" value={store.entryRelationTypeId}
                      onChange={(event) => {
                        store.setEntryRelationTypeId(event.target.value);
                      }}
                      onBlur={(event) => {
                        setFieldsTouched("entryRelationTypeId");
                        handleValueChange(event,
                          "entryRelationTypeId",
                          store.entryRelationTypeId,
                          savedState.entryRelationTypeId);
                      }}
                      className="bds-form-select bds-section-select form-select mt-2">
                      <option value="">Please Select ...</option>
                      {
                        entryRelationshipTypes.map((item, index) => (
                          <option key={index} value={item.entryRelationshipTypeId}>
                            {item.entryRelationshipTypeName}
                          </option>
                        ))
                      }
                    </select>
                  </div>
                  <div className="col-12 mt-3">
                    <ShowThen when={store.entryRelationTypeId == "6"}>
                      <BdsCheckbox 
                         id="internal-bds-data-entry"
                         name="entryRelationIsBdds" 
                         checked={store.entryRelationIsBdds}
                          onChange={(event) => {
                            store.setEntryRelationIsBdds(event.target.checked);
                            setFieldsTouched("entryRelationIsBdds");
                            handleValueChange(event,
                              "entryRelationIsBdds",
                              store.entryRelationIsBdds,
                              savedState.entryRelationIsBdds);
                          }}
                          onBlur={(e) => {
                            setFieldsTouched("entryRelationIsBdds");
                          }}
                          label="BDS Internal Data Entry"
                      />
                    </ShowThen>
                  </div>
                </div>
              </Card>
            </div>
          </ShowThen>

          <div className="mt-4">
            <Card>
              <div className="bds-section-heading">
                What is the name of the individual applying for BDS Services?
              </div>
              <div className="bds-section-details mt-2">
                Please enter a first and last name for the applicant. Middle name and suffix are optional.
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <BdsCheckbox 
                    id="no-first-name"
                    name="noFirstName" checked={store.noFirstName}
                    onChange={(event) => {
                      store.setNoFirstName(event.target.checked);
                      store.setFirstName("");
                      setFieldsTouched("noFirstName", "firstName");
                      handleValueChange(event,
                        "noFirstName",
                        store.noFirstName,
                        savedState.noFirstName)
                    }}
                    onBlur={(e) => {
                      setFieldsTouched("noFirstName", "firstName");
                    }}
                    label="No First Name"
                  />
                </div>
                <div className="col-12 col-md-6 mt-3">
                  <label className="bds-form-label form-label mandatory">First Name</label>
                  <input type="text" name="firstName" value={store.firstName}
                    placeholder={store.noFirstName ? "No First Name" : ""}
                    onChange={(event) => { store.setFirstName(event.target.value); }}
                    onBlur={(event) => {
                      setFieldsTouched("firstName");
                      handleValueChange(event,
                        "firstName",
                        store.firstName,
                        savedState.firstName)
                    }}
                    disabled={store.noFirstName}
                    className="bds-form-text bds-grid-form form-control" maxLength={35} />
                </div>
                <div className="col-12 col-md-6 mt-3">
                  <label className="bds-form-label form-label mandatory">Last Name</label>
                  <input type="text" name="lastName" value={store.lastName}
                    onChange={(event) => { store.setLastName(event.target.value); }}
                    onBlur={(event) => {
                      setFieldsTouched("lastName");
                      handleValueChange(event,
                        "lastName",
                        store.lastName,
                        savedState.lastName);
                    }}
                    className="bds-form-text bds-grid-form form-control" maxLength={35} />
                </div>
                <div className="col-12 col-md-6 mt-3">
                  <label className="bds-form-label form-label">Middle Name</label>
                  <input type="text" name="middleName" value={store.middleName}
                    onChange={(event) => { store.setMiddleName(event.target.value); }}
                    onBlur={(event) => {
                      setFieldsTouched("middleName");
                      handleValueChange(event,
                        "middleName",
                        store.middleName,
                        savedState.middleName);
                    }}
                    className="bds-form-text bds-grid-form form-control" maxLength={35} />
                </div>
                <div className="col-12 col-md-6 mt-3">
                  <label className="bds-form-label form-label">Suffix Type</label>
                  <select name="suffixTypeId" value={store.suffixTypeId}
                    onChange={(event) => {
                      store.setSuffixTypeId(event.target.value);
                    }}
                    onBlur={(event) => {
                      setFieldsTouched("suffixTypeId");
                      handleValueChange(event,
                        "suffixTypeId",
                        store.suffixTypeId,
                        savedState.suffixTypeId);
                    }}
                    className="bds-form-select bds-grid-form form-select">
                    <option value="">Please Select ...</option>
                    {
                      suffixTypes.map((item, index) => (
                        <option key={index} value={item.suffixTypeId}>{item.suffixTypeName}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
            </Card>
          </div>

          <div className="mt-4">
            <Card>
              <div className="bds-section-heading mandatory">What is the applicant's date of birth?</div>
              <div className="bds-section-details mt-2">
                Please enter in the following format: XX/XX/XXXX. You do not need to add a zero at
                the beginning of single digit months or days.
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <BdsDatePicker
                    id='date-of-birth'
                    name="dateOfBirth" 
                    value={store.dateOfBirth}
                    onChange={(event) => { store.setDateOfBirth(event.target.value); }}
                    onBlur={(event) => {
                      setFieldsTouched("dateOfBirth");
                      if (Utility.isHtmlDateStringValid(event.target.value)) {
                        handleValueChange(event,
                          "dateOfBirth",
                          store.dateOfBirth,
                          savedState.dateOfBirth)
                      }
                    }}
                    cssClass="bds-form-text bds-section-dob form-control"
                  />
                </div>
              </div>
            </Card>
          </div>

          <div className="mt-4">
            <Card>
              <div className="bds-section-heading mandatory">Does the applicant currently have Medicaid?</div>
              <div className="mt-3">
                <GroupButtons name="hasMedicaid" value={store.hasMedicaid}
                  onChange={(event) => {
                    setFieldsTouched("hasMedicaid");
                    store.setHasMedicaid(event.value);
                    if (store.hasMedicaid != "has-medicaid") {
                      store.setMedicaid("");
                    }
                  }}
                  onBlur={(event) => {
                    setFieldsTouched("hasMedicaid", "medicaid");
                    handleValueChange(event,
                      "hasMedicaid",
                      store.hasMedicaid,
                      savedState.hasMedicaid);
                  }}
                  items={[
                    { itemValue: "has-medicaid", content: <>Yes</> },
                    { itemValue: "has-not-medicaid", content: <>No</> }
                  ]}>
                </GroupButtons>
              </div>
            </Card>
          </div>

          <ShowThen when={store.hasMedicaid == 'has-medicaid'}>
            <div className="mt-4">
              <Card>
                <div className="bds-section-heading">What is the applicant's Medicaid number?</div>
                <div className="bds-section-details mt-2">
                  A Medicaid number is also known as a RID which means Recipient Identification.
                  This is a 12-digit client identification number and is found on the Medicaid card
                  issued to the applicant. If the applicant does not have or does not know this number,
                  leave this blank and move to the next question.
                </div>
                <div className="row mt-3">
                  <div className="col-12">
                    <input type="text" name="medicaid" value={store.medicaid}
                      onChange={(event) => { store.setMedicaid(event.target.value); }}
                      onBlur={(event) => {
                        setFieldsTouched("medicaid");
                        handleValueChange(event,
                          "medicaid",
                          store.medicaid,
                          savedState.medicaid);
                      }}
                      className="bds-form-text bds-grid-form form-control" maxLength={12} />
                  </div>
                </div>
              </Card>
            </div>
          </ShowThen>

          <div className="row mt-4">
            <div className="col-12 col-md-6 order-1 order-md-0 mt-3 mt-md-0">
              <button type="button" className="btn bds-btn-primary"
                onClick={() => NavigationHelper.gotoStartApplication(navigate, applicationRefId)}>Back</button>
              <IsAuthenticated>
                <button type="button" className="btn bds-btn-primary ms-0 ms-md-3 mt-3 mt-md-0"
                  onClick={() => NavigationHelper.gotoDashboard(navigate)}>Save for Later</button>
              </IsAuthenticated>
            </div>
            <div className="col-12 col-md-6 order-0 order-md-1 text-start text-md-end mt-3 mt-md-0">
              <ShowThen when={fromSummary}>
              <button type="button" disabled={isLoading} 
                onClick={() => handleReturnToSummary()} 
                className="btn bds-btn-primary me-3">Return To Summary
              </button>
              </ShowThen>
              <button type="button" disabled={isLoading} onClick={(event) => handleGoToNextStep()}
                className="btn bds-btn-primary">Next</button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default observer(ApplicationInformation);