import * as Yup from "yup";
import { INbarDemographicInformation } from "./demographic-information.store";
import { Utility } from "../../../../shared-module/helpers/utility";
import { AppSettings } from "app-setting";

// validation schema
export const NbarDemographicInformationValidationSchema: Yup.ObjectSchema<INbarDemographicInformation> = Yup.object({
    hasMedicaid: Yup.number()
        .test("validate-hasMedicaid", "-", function (item) {
            if (item < 0) {
            return this.createError(
                Utility.buildYupErrorMessage("hasMedicaid",
                "Please select an answer for Has Medicaid question"));
            }
            return true;
        }),
    medicaidNumber: Yup.string()
        .test("validate-medicaidNumber", "-",
            function (item) {
            if (item) {
                if (item.length < 12) {
                return this.createError(
                    Utility.buildYupErrorMessage("medicaidNumber", "A Medicaid | RID number must 12-digits long"));
                } else if (!new RegExp(/^[1][0-9]{9}[9][9]$/).test(item)) {
                return this.createError(
                    Utility.buildYupErrorMessage("medicaidNumber",
                    "A Medicaid | RID number must be all numerical that start with a 1 and end with 99"));
                }
            }
            return true;
        }),
    medicaidCategoryTypeId: Yup.number().nullable()
        .test("validate-medicaidCategoryTypeId", "-", function (item) {
            if (!item && this.parent.hasMedicaid == 1) {
                return this.createError(
                    Utility.buildYupErrorMessage("medicaidCategoryTypeId",
                        "Please select the appropriate Medicaid Category"));
                }
            return true;
        }),
    hasSSI: Yup.number()
        .test("validate-hasSSI", "-", function (item) {
            if (item < 0) {
                return this.createError(
                    Utility.buildYupErrorMessage("hasSSI",
                    "Please select an answer for Has SSI question"));
                }
            return true;
        }),
    hasGuardian: Yup.number()
        .test("validate-hasGuardian", "-", function (item) {
            if (item < 0) {
                return this.createError(
                    Utility.buildYupErrorMessage("hasGuardian",
                    "Please select an answer for the question \"Does the individual have a guardian?\""));
                }
            return true;
        }),
    nameOfGuardian: Yup.string()
        .test("validate-nameOfGuardian", "-", function (item) {
            if (!item && this.parent.hasGuardian == 1) {
                return this.createError(
                    Utility.buildYupErrorMessage("nameOfGuardian",
                    "Please provide the Guardian Name"));
                }
            return true;
        }),
    hasPlacement: Yup.number()
        .test("validate-hasPlacement", "-", function (item) {
            if (item < 0) {
                return this.createError(
                    Utility.buildYupErrorMessage("hasPlacement",
                    "Please select an answer for Has Placement question"));
                }
            return true;
        }),
    placementAgency: Yup.string()
        .test("validate-placementAgency", "-", function (item) {
            if (!item && this.parent.hasPlacement == 1) {
                return this.createError( 
                    Utility.buildYupErrorMessage("placementAgency",
                    "Please provide the Placement Agency Name"));
                }
            return true;
        }),
    placementAgencyDate: Yup.string()
        .test("validate-placementAgencyDate", "-", function (item) {
            if (!item && this.parent.hasPlacement == 1) {
                return this.createError( 
                    Utility.buildYupErrorMessage("placementAgencyDate",
                    "Please provide the Placement Agency Date"));
                }
            return true;
        }),
    placementConsidered: Yup.number()
        .test("validate-placementConsidered", "-", function (item) {
            if (item < 0) {
                return this.createError(
                    Utility.buildYupErrorMessage("placementConsidered",
                    "Please select an answer for Has Placement Been Considered question"));
                }
            return true;
        }),
    placementNotesYes: Yup.string().nullable()
        .test("validate-placementNotesYes", "-", function (item) {
            if (!item && this.parent.placementConsidered == 1) {
                return this.createError( 
                    Utility.buildYupErrorMessage("placementNotesYes",
                    "Please provide an answer for Description of Placement Outcome"));
                }
            return true;
        }),
    placementNotesNo: Yup.string().nullable()
        .test("validate-placementNotesNo", "-", function (item) {
            if (!item && this.parent.placementConsidered == 0) {
                return this.createError( 
                    Utility.buildYupErrorMessage("placementNotesNo",
                    "Please provide an answer for Reason for Not Exploring Placement"));
                }
            return true;
        }),
    hasCurrentDDRS: Yup.number()
        .test("validate-hasCurrentDDRS", "-", function (item) {
            if (item < 0) {
            return this.createError(
                Utility.buildYupErrorMessage("hasCurrentDDRS",
                "Please select an answer for Has Waiver question"));
            }
            return true;
        }),
    ddrsSelectedOptions: Yup.array().nullable()
        .test("validate-ddrsSelectedOptions", "-", function (item) {
            if (item.length < 1 && this.parent.hasCurrentDDRS == 1) {
                return this.createError(
                    Utility.buildYupErrorMessage("hasCurrentDDRS",
                        "Please select the appropriate DDRS services"));
                }
            return true;
        }),
    ddrsOtherText: Yup.string().nullable()
        .test("validate-ddrsOtherText", "-", function (item) {
            if (!item && this.parent.ddrsSelectedOptions?.find(x => x.label.toLowerCase() == 'other')){
                return this.createError(
                    Utility.buildYupErrorMessage("ddrsOtherText",
                        "Please provide the other DDRS service information"));
                }
            return true;
        }), 
    naturalSupportAvailable: Yup.string()
        // .required("Please provide an answer for Informal Supports Available")
        .test("validate-naturalSupportAvailable", "-", function (item) {
            if (!item) {
                return this.createError( 
                    Utility.buildYupErrorMessage("naturalSupportAvailable",
                    "Please provide an answer for Informal Supports Available"))
                }
            return true;
        }),
    naturalSupportComments: Yup.string()
        // .required("Please provide an answer for How are Informal Supports Utilized")
        .test("validate-naturalSupportComments", "-", function (item) {
            if (!item) {
                return this.createError( 
                    Utility.buildYupErrorMessage("naturalSupportComments",
                    "Please provide an answer for How are Informal Supports Utilized"));
                }
            return true;
        }),
    hasCommunitySupportExplored: Yup.number()
        .test("validate-hasCommunitySupportExplored", "-", function (item) {
            if (item < 0) {
                return this.createError(
                    Utility.buildYupErrorMessage("hasCommunitySupportExplored",
                    "Please select an answer for Has Community Support Been Explored question"));
                }
            return true;
        }),
    communitySupportComments: Yup.string()
        .test("validate-communitySupportComments", "-", function (item) {
            if (!item && this.parent.hasCommunitySupportExplored == 1) {
                return this.createError(
                    Utility.buildYupErrorMessage("communitySupportComments",
                    "Please select an answer for How Community Supports Been Utilized"));
                }
            return true;
        }),
    hasLostPrimaryCaregiver: Yup.number()
        .test("validate-hasLostPrimaryCaregiver", "-", function (item) {
            if (item < 0) {
            return this.createError(
                Utility.buildYupErrorMessage("hasLostPrimaryCaregiver",
                "Please select an answer for Has Lost Primary Caregiver question"));
            }
            return true;
        }),
    dateOfPrimaryCaregiverLoss: Yup.string().nullable()
        .test("validate-dateOfPrimaryCaregiverLoss", "-", function (item) {
            var date = Utility.fromHtmlDateString(item, null);
            if (!item && this.parent.hasLostPrimaryCaregiver == 1) {
                return this.createError(
                    Utility.buildYupErrorMessage("dateOfPrimaryCaregiverLoss", "Date of Primary Caregiver Loss is required."));
            } else if (date == null && this.parent.hasLostPrimaryCaregiver == 1) {
                return this.createError(
                    Utility.buildYupErrorMessage("dateOfPrimaryCaregiverLoss", "Date of Primary Caregiver Loss is invalid."));
            } else if (date > Utility.getToday() && this.parent.hasLostPrimaryCaregiver == 1) {
                return this.createError(
                    Utility.buildYupErrorMessage("dateOfPrimaryCaregiverLoss", "Date of Primary Caregiver Loss cannot be in the future."));
            } else if (date <= Utility.getMinimumAge() && this.parent.hasLostPrimaryCaregiver == 1) {
                return this.createError(
                    Utility.buildYupErrorMessage("dateOfPrimaryCaregiverLoss", "Date of Primary Caregiver Loss cannot be too much in the past."));
            }
            return true;
        }),
    primaryCaregiverCapacityDescription: Yup.string()
        .test("validate-primaryCaregiverCapacityDescription", "-", function (item) {
            if (!item && this.parent.hasLostPrimaryCaregiver == 1) {
            return this.createError(
                Utility.buildYupErrorMessage("primaryCaregiverCapacityDescription",
                "Please provide a description for Primary Caregiver Capacity"));
            }
            return true;
        }),
    currentCaregiverArrangementDescription: Yup.string()
        .test("validate-currentCaregiverArrangementDescription", "-", function (item) {
            if (!item && this.parent.hasLostPrimaryCaregiver == 1) {
            return this.createError(
                Utility.buildYupErrorMessage("currentCaregiverArrangementDescription",
                "Please provide an answer for Describe the Current Primary Caregiver Arrangement"));
            }
            return true;
        }),
    primaryCaregiverOverEighty: Yup.number()
        .test("validate-primaryCaregiverOverEighty", "-", function (item) {
            if (item < 0 && this.parent.hasLostPrimaryCaregiver == 0) {
            return this.createError(
                Utility.buildYupErrorMessage("primaryCaregiverOverEighty",
                "Please select an answer for Is Primary Caregiver Over Eighty question"));
            }
            return true;
        }),
    hasSecondaryCaregiver: Yup.number()
        .test("validate-hasSecondaryCaregiver", "-", function (item) {
            if (item < 0) {
            return this.createError(
                Utility.buildYupErrorMessage("hasSecondaryCaregiver",
                "Please select an answer for Has Secondary Caregiver question"));
            }
            return true;
        }),
    secondaryCaregiverLessThanEighty: Yup.number()
        .test("validate-secondaryCaregiverLessThanEighty", "-", function (item) {
            if (item < 0 && this.parent.hasSecondaryCaregiver == 1) {
                return this.createError(
                    Utility.buildYupErrorMessage("secondaryCaregiverLessThanEighty",
                    "Please select an answer for Secondary Caregiver Less Than Eighty question"));
                }
            return true;
        }),
    abuseNeglectSubstantiated: Yup.number()
        .test("validate-abuseNeglectSubstantiated", "-", function (item) {
            if (item < 0) {
                return this.createError(
                    Utility.buildYupErrorMessage("abuseNeglectSubstantiated",
                    "Please select an answer for Has Abuse Or Neglect Been Substantiated question"));
                }
            return true;
        }),
    abuseNeglectDescription: Yup.string()
        .test("validate-abuseNeglectDescription", "-", function (item) {
            if (!item && this.parent.abuseNeglectSubstantiated == 1) {
                return this.createError(
                    Utility.buildYupErrorMessage("abuseNeglectDescription",
                    "Please select an answer for Who Was the Perpetrator of the Abuse"));
                }
            return true;
        }),  
    hasExtraordinarySafetyRisk: Yup.number()
        .test("validate-hasExtraordinarySafetyRisk", "-", function (item) {
            if (item < 0) {
            return this.createError(
                Utility.buildYupErrorMessage("hasExtraordinarySafetyRisk",
                "Please select an answer for Has Extraordinary Safety Risk question"));
            }
            return true;
        }),
    extraordinarySafetyRiskDescription: Yup.string()
        .test("validate-extraordinarySafetyRiskDescription", "-", function (item) {
            if (!item && this.parent.hasExtraordinarySafetyRisk == 1) {
                return this.createError(
                    Utility.buildYupErrorMessage("extraordinarySafetyRiskDescription",
                    "Please select an answer for Extraordinary Safety Risk Description"));
                }
            return true;
        }),
    isMinor: Yup.boolean().nullable(),
    guardianshipFileName: Yup.string().nullable(),
    guardianshipFile: Yup.mixed<File>().nullable()
        .test("validate-guardianshipFile", "-", function (value) {
            if (!validateFileSize(value)) {
            return this.createError(
                Utility.buildYupErrorMessage("guardianshipFile",
                "Guardianship file is too large"));
            }
            return true;
      })
      .test("validate-guardianshipFileType", "-", function (value) {
            if (!validateFileType(value)) {
            return this.createError(
                Utility.buildYupErrorMessage("guardianshipFile",
                "Guardianship file type is not supported"));
            }
            return true;
      }),
});

function validateFileSize(value: File) {
    if (value) {
      return value.size <= AppSettings.maxUploadFileSize
    }
    return true;
  }
  
  function validateFileType(value: File) {
    if (value) {
      const validFileTypes = AppSettings.validUploadFileFormats;
      const extension = value.name.split('.').pop().toLowerCase();
      return validFileTypes.filter(type => type == `.${extension}`).length > 0;
    }
    return true;
  }