// --GLOBAL_LIBRARY
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";

// --APP_MODULE
// -- --  ./local_component_imports
import { AppSettings } from "app-setting";
import { INbarDemographicInformation, NbarDemographicInformation } from "./demographic-information.store";
import { NbarDemographicInformationValidationSchema } from "./demographic-information.validation";

// -- --  ../extensions
import { PageTitleSetter } from "../../../extensions/page-title-setter/page-title-setter.component";
import { ApplicationProgressBar } from "../../../extensions/application-progress-bar/application-progress-bar.component";

// --SHARED_MODULE
// -- --  ../resources
import { LookupResource } from "../../../../shared-module/api-resources/lookup.resource";
import { NbarDemographicsResource } from "../../../../shared-module/api-resources/Nbar/nbar-demographics.resource";
import { NbarApplicationResource } from "../../../../shared-module/api-resources/Nbar/nbar-application.resource";
import { NbarDocumentResource } from "../../../../shared-module/api-resources/Nbar/nbar-document.resource";
import { NbarOtherResource } from "../../../../shared-module/api-resources/Nbar/nbar-other.resource";

// -- --  ../components
import { Card } from "../../../../shared-module/components/card/card.component";
import { ShowThen } from "../../../../shared-module/components/show-then/show-then.component";
import { DebugContainer } from "../../../../shared-module/components/debug-container/debug-container.component";
import { ErrorContainer } from "../../../../shared-module/components/error-container/error-container.component";
import { IsAuthenticated } from "../../../../shared-module/components/is-authenticated/is-authenticated";
import { BdsMultiSelect, BdsSelectOption } from "../../../../shared-module/components/multi-select/multi-select.component";
import { RadioButtonGroup } from "../../../../shared-module/components/radio-button-group/radio-button-group.component";
import { NbarTextArea } from "../../../../shared-module/components/nbar-text-area/nbar-text-area.component";

// -- --  ../helpers
import * as enums from '../../../../shared-module/helpers/enums'
import { NavigationHelper } from "../../../../shared-module/helpers/navigation-helper";
import { Utility } from "../../../../shared-module/helpers/utility";

// -- --  ../hooks
import { useFormValidation } from "../../../../shared-module/hooks/use-form-validation/use-form-validation.hook";

// -- --  ../models
import { GatewayOapApi } from "../../../../shared-module/models/gateway-oap-api.models";
import { ApplicationExpirationError, GatewayNetworkError } from "../../../../shared-module/models/common.models";

// -- --  ../services
import { ApiConfig } from "../../../../shared-module/services/api-config";
import { SessionService } from "../../../../shared-module/services/session.service";
import BdsDatePicker from "shared-module/components/date-picker/bds-date-picker.component";
import { EventBroadcasterService } from "shared-module/events/event-broadcaster.service";



const DemographicInformation = () => {
    // view model
    const store = NbarDemographicInformation;

    // ./hooks
    const navigate = useNavigate();
    const { id: nbarApplicationRefId } = useParams();
    const { errors, touched, setIsSubmitting, getFieldState, setFieldsTouched, touchedAll } = 
        useFormValidation(NbarDemographicInformationValidationSchema, store);

    // ./resources
    const demographicService = new NbarDemographicsResource(ApiConfig);
    const applicationService = new NbarApplicationResource(ApiConfig);
    const lookupService = new LookupResource(ApiConfig);
    const documentService = new NbarDocumentResource(ApiConfig);
    const otherService = new NbarOtherResource(ApiConfig);

    // ./form processing
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [savedState, setSavedState] = useState<INbarDemographicInformation>({} as INbarDemographicInformation);
    const [isSaving, setIsSaving] = useState<boolean>(false);

    // ../component state 
    const [demographicInformationId, setDemographicInformationId] = useState<number>(null);
    const [medicaidCategoryOptions, setMedicaidCategoryOptions] = useState<GatewayOapApi.MedicaidCategoryTypesModel[]>([]);
    const [YesNoRadioOptions, setYesNoRadioOptions] = useState([]);
    const [savedWaiverOptions, setSavedWaiverOptions] = useState<GatewayOapApi.WaiverInformationModel[]>([]);
    const [isDocumentLoading, setIsDocumentLoading] = useState<boolean>(false);
    const [hasGuardianshipFile, setHasGuardianshipFile] = useState<boolean>(false);

    

    // LOCAL FORM PROCESSING
    // ../GuardianShip
    function handleGuardianshipUploadValueChange(e) {
        setIsLoading(true);
        setIsDocumentLoading(true);
        store.setGuardianshipFile(e.target.files[0]);
        if (NbarDemographicInformationValidationSchema.validateAt("guardianshipFile", store)) {
          documentService.saveStagedApplicationDocuments(nbarApplicationRefId, e.target.files[0], 3)
            .then(() => {
                return documentService.getStagedApplicationDocuments(nbarApplicationRefId);
            })
            .then((response) => {
                const docName = response?.records[0]?.documentName;
                store.setGuardianshipFileName(docName);
                setHasGuardianshipFile(!!docName);
                // console.log(`DOC NAME: | HAS GUARDIANSHIP FILE: ${docName} | ${hasGuardianshipFile}`);
            })
            .catch((ex) => {
              store.setGuardianshipFile(null);
              store.setGuardianshipFileName(null);
              Utility.alert("Unexpected Error", ex);
            })
            .finally(() => {
              setIsLoading(false);
              setIsDocumentLoading(false);
            });
        }
    }
   
    async function handleRemoveGuardianshipFile() {
        if (!isLoading) {
            documentService.removeStagedApplicationDocuments(nbarApplicationRefId, 3)
            .then(() => {
                store.setGuardianshipFile(null);
                store.setGuardianshipFileName(null);
                setHasGuardianshipFile(false);
            })
            .catch((ex) => {
              Utility.alert("Unexpected Error", ex);
            })
            .finally(() => {});
        }
    }

    function checkRemoveGuardianFile(e) {
        if(e.target.value < enums.YesNoNARadioButtonGroupOptions.Yes) {
            handleRemoveGuardianshipFile();
        }
    }

    // ../WaiverOptions
    function handleUpdateWaiverOptions(event) {
        const selectedIds = store.ddrsSelectedOptions.map(({value}) => Number(value));
        const savedIds = savedWaiverOptions.map((item) => item.waiverInformationId);

        if(selectedIds.length > savedIds.length) {
            const addId = selectedIds.slice(-1)[0];
            otherService.addWaiverInformation(demographicInformationId, addId, '')
            .then(() => {
                demographicService.getNbarDemographics(demographicInformationId)
                .then((response) => {
                    const savedOptions = response.record?.waiverInformation;
                    setSavedWaiverOptions(savedOptions);
                })
            })
        }
        else if (selectedIds < savedIds) {
            const deleteId = savedIds.filter(x => !selectedIds.includes(x));
            otherService.deleteWaiverInformation(deleteId[0])
            .then(() => {
                demographicService.getNbarDemographics(demographicInformationId)
                .then((response) => {
                    const savedOptions = response.record?.waiverInformation;
                    setSavedWaiverOptions(savedOptions);
                })
            })
        } 
    }

    function handleAddOtherWaiverOption(event) {
        const otherWaiverId = Number(store.waiverOptions.find(x => x.label.toLowerCase() == 'other').value);
        const savedId = savedWaiverOptions.find(x => x.waiverTypeId == otherWaiverId).waiverInformationId;
        otherService.deleteWaiverInformation(savedId)
        .then(() => {
            otherService.addWaiverInformation(demographicInformationId, otherWaiverId, store.ddrsOtherText);
        })
    }

    function checkClearAllWaivers(event) {
        if(event == enums.YesNoNARadioButtonGroupOptions.No) {
            savedWaiverOptions.forEach((item) => {
                otherService.deleteWaiverInformation(item.waiverInformationId);
            })
        }
        setSavedWaiverOptions([]);
    }

    // GLOBAL FORM PROCESSING AND NAVIGATION
    // ../"Public Methods"
    function handleValueChange<T>(event, eventSource: string, currentValue: T, savedValue: T) {
        if (currentValue === savedValue) { return; }
        setIsSubmitting(true, eventSource);
        submit()
        .finally(() => {
            setIsSubmitting(false, eventSource);
        })
    }

    const handleGoToNextStep = () => {
        validate().then(() => {
            setIsSaving(true);
            submit().then(() => {
                NavigationHelper.gotoNbarTreatmentInformation(navigate, nbarApplicationRefId);
            })
        })
        .catch((error) => {
            console.log('error: ', error);
            scrollTo(0, 0);
        })
        .finally(() => {
            setIsSaving(false);
        })
    }

    // ../"Private Methods"
    function submit(): Promise<any> {
        // the call to applicationService.getApplication() is needed to ensure the application has not expired.
        // TODO: refactor this out for nBar. We do not want to pull the application data on each call.
        // --- will need an alternate way of determining current application status
        setIsSaving(true); 
        return applicationService.getApplication(nbarApplicationRefId)
        .then(() => {
            saveDemographicInformation()
            .then(() => {
                var guestSessionId = sessionStorage.getItem("guestSessionId") || "0";
                if(guestSessionId != "0") {
                    SessionService.slideGuestSession(Number(guestSessionId));
                    EventBroadcasterService.RaiseGuestSessionExpirationEvent({
                        isAuthenticated: false,
                        remainingTimeInSeconds: 900,
                        hasActiveMaintenance: false
                    });
                }
            })  
        })     
        .catch((ex) => {            
            if(ex instanceof ApplicationExpirationError) {
                Utility.alert("Application Timeout", ex.errorMessage);
            }
            else if (!(ex instanceof GatewayNetworkError)) {
                Utility.alert("Error Saving", ex);
            }
            NavigationHelper.gotoNbarHome(navigate);
        })
        .finally(() => {
            setIsSaving(false);
        })
    }

    function validate() : Promise<any> {
        touchedAll();
        return NbarDemographicInformationValidationSchema.validate(store)
        .then((response) => {
            NbarDemographicInformationValidationSchema.isValidSync(store);
        })
        .catch((error) => {
            throw error;
        });
    }

    function saveDemographicInformation(): Promise<any> { 
        //TODO: map store model to api model and pass to service below   
        return demographicService.saveNbarDemographics(
            Utility.stringToNumber(nbarApplicationRefId, 0),
            demographicInformationId, 
            store.hasMedicaid,
            store.medicaidNumber,
            store.medicaidCategoryTypeId,
            store.hasSSI,
            store.hasGuardian,
            store.nameOfGuardian,
            store.hasPlacement,
            store.placementAgency,
            store.placementAgencyDate,
            store.placementConsidered,
            store.placementNotesYes,
            store.placementNotesNo,
            store.hasCurrentDDRS,
            store.naturalSupportAvailable,
            store.naturalSupportComments,
            store.hasCommunitySupportExplored,
            store.communitySupportComments,
            store.hasLostPrimaryCaregiver,
            Utility.toDate(store.dateOfPrimaryCaregiverLoss),
            store.primaryCaregiverCapacityDescription,
            store.currentCaregiverArrangementDescription,
            store.primaryCaregiverOverEighty,
            store.hasSecondaryCaregiver,
            store.secondaryCaregiverLessThanEighty,
            store.abuseNeglectSubstantiated,
            store.abuseNeglectDescription,
            store.hasExtraordinarySafetyRisk,
            store.extraordinarySafetyRiskDescription
        )
        .catch(ex => {
            throw ex;
        })
    }


    // PAGE LOAD
    useEffect(() => { 
        setIsLoading(true);
        Utility.showLoadingOverlay();
        setYesNoRadioOptions(Utility.getRadioGroupYesNoOptions());

        Promise.all([
            lookupService.getWaiverTypes(),
            applicationService.getApplication(nbarApplicationRefId),
            documentService.getStagedApplicationDocuments(nbarApplicationRefId),
            lookupService.getMedicaidCategoryTypes(),
        ])
        .then((response) => {
            store.setWaiverOptions(response[0].records);
            setMedicaidCategoryOptions(response[3].records);
            setDemographicInformationId(response[1].record?.demographicInformationId);
            store.setIsMinor(response[1].record.dateOfBirth);
            const docName = response[2].records[0]?.documentName;
            store.setGuardianshipFileName(docName);
            setHasGuardianshipFile(!!docName);
            return demographicService.getNbarDemographics(response[1].record?.demographicInformationId);
        })
        .then((response) => {
            store.refresh(response.record);
            setSavedState(store.buildState(response.record));
            setSavedWaiverOptions(response.record.waiverInformation);
        })
        .catch((ex) => {
            if(ex instanceof ApplicationExpirationError) {
                Utility.alert("Application Timeout", ex.errorMessage);
            }
            else if (!(ex instanceof GatewayNetworkError)) {
                Utility.alert("Error Saving", ex);
            }
            NavigationHelper.gotoNbarHome(navigate); 
        })
        .finally(() => {
            setIsLoading(false);
            Utility.hideLoadingOverlay();
        });

    }, [])

    // COMPONENT MARKUP
    return (
        <>
            <DebugContainer data={store} saved={savedState} touched={touched} errors={errors}></DebugContainer>

            <PageTitleSetter step={2} totalSteps={6}></PageTitleSetter>
            <div className="mt-4">
                <ApplicationProgressBar step={2} totalSteps={6}></ApplicationProgressBar>
            </div>

            <ShowThen when={!isLoading}>
                <ErrorContainer data={store} saved={savedState} touched={touched} errors={errors}></ErrorContainer>
            </ShowThen>

            <form>
                {/* MEDICAID INFORMATION */}
                <div className="mt-4">
                    <Card>
                        <div className="bds-section-heading mandatory">Medicaid Information</div>
                        <div className="row mt-3">
                            <RadioButtonGroup
                                labelText="Does the individual have current Medicaid?"
                                radioGroupOptions={YesNoRadioOptions}
                                name="hasMedicaid"
                                selectedValue={store.hasMedicaid}
                                onChange={(event) => {
                                    store.setHasMedicaid(Number(event.target.value))
                                    setFieldsTouched("hasMedicaid", "medicaidNumber", "medicaidCategory")
                                    handleValueChange(event, 
                                        "hasMedicaid", 
                                        store.hasMedicaid, 
                                        savedState.hasMedicaid
                                    )
                                }}
                            ></RadioButtonGroup>
                            <ShowThen when={store.hasMedicaid == enums.YesNoNARadioButtonGroupOptions.Yes}>
                                <div className="row mt-3">
                                    <div className="col-12 col-md-6">
                                        <label className="bds-form-label form-label" htmlFor="medicaid-number">Medicaid Number:</label>
                                        <input type="text" id="medicaid-number" name="medicaidNumber" value={store.medicaidNumber}
                                            onChange={(event) => { store.setMedicaidNumber(event.target.value); }}
                                            onBlur={(event) => {
                                                setFieldsTouched("medicaidNumber");
                                                handleValueChange(event,
                                                "medicaidNumber",
                                                store.medicaidNumber,
                                                savedState.medicaidNumber);
                                            }}
                                            className="form-control" maxLength={12} />
                                    </div>
                                {/* </div>
                                <div className="row mt-3"> */}
                                    <div className="col-12 col-md-6">
                                        <label className="bds-form-label form-label" htmlFor="medicaid-catgory-type">Category:</label>
                                        <select name="medicaidCategoryTypeId" value={store.medicaidCategoryTypeId}
                                            onChange={(event) => {
                                                store.setMedicaidCategoryTypeId(Number(event.target.value));
                                            }}
                                            onBlur={(event) => {
                                                setFieldsTouched("medicaidCategoryTypeId");
                                                handleValueChange(event,
                                                "medicaidCategoryTypeId",
                                                store.medicaidCategoryTypeId,
                                                savedState.medicaidCategoryTypeId);
                                            }}
                                            className="bds-form-select bds-grid-form form-select">
                                            <option value="">Please Select ...</option>
                                            {
                                                medicaidCategoryOptions.map((item, index) => (
                                                <option key={index} value={item.benefitPlanDescriptionId}>{item.bddsPreferredDescription}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                            </ShowThen>
                        </div>
                        <div className="row mt-3">
                            <RadioButtonGroup
                                labelText="Does the individual receive SSI/SSDI?"
                                radioGroupOptions={YesNoRadioOptions}
                                name="hasSSI"
                                selectedValue={store.hasSSI}
                                onChange={(event) => {
                                    store.setHasSSI(Number(event.target.value))
                                    setFieldsTouched("hasSSI")
                                    handleValueChange(event, 
                                        "hasSSI", 
                                        store.hasSSI, 
                                        savedState.hasSSI
                                    )
                                }}
                            ></RadioButtonGroup>
                        </div>
                    </Card>
                </div>

                {/* GUARDIAN INFORMATION */}
                <div className="mt-4">
                    <Card>
                        <div className="bds-section-heading mandatory">Guardian information</div>
                        <div className="row mt-3">
                            <RadioButtonGroup
                                labelText="Does the individual have a guardian?"
                                radioGroupOptions={YesNoRadioOptions}
                                name="hasGuardian"
                                selectedValue={store.hasGuardian}
                                onChange={(event) => {
                                    store.setHasGuardian(Number(event.target.value))
                                    setFieldsTouched("hasGuardian", "nameOfGuardian")
                                    handleValueChange(event, 
                                        "hasGuardian", 
                                        store.hasGuardian, 
                                        savedState.hasGuardian
                                    )
                                    checkRemoveGuardianFile(event);
                                }}
                            ></RadioButtonGroup>

                            <ShowThen when={store.hasGuardian == enums.YesNoNARadioButtonGroupOptions.Yes}>
                                <div className="row mt-3">
                                    <div className="col-md-6">
                                        <label className="form-check-label" htmlFor="medicaid-number">Name of Guardian:</label>
                                        <input type="text" id="guardian-name" name="nameOfGuardian" value={store.nameOfGuardian}
                                            onChange={(event) => { store.setNameOfGuardian(event.target.value); }}
                                            onBlur={(event) => {
                                                setFieldsTouched("nameOfGuardian");
                                                handleValueChange(event,
                                                "nameOfGuardian",
                                                store.nameOfGuardian,
                                                savedState.nameOfGuardian);
                                            }}
                                            className="form-control" maxLength={50} />
                                    </div>
                                    <div className="col-md-6">
                                        <span>Please attach a copy of guardianship papers:</span>                                
                                        <div className="bds-document-upload-wrapper">
                                            <label aria-label="guardianshipFile" htmlFor="guardianshipFile" className="btn bds-btn-primary me-2">
                                                <ShowThen when={isDocumentLoading}>
                                                    <div className="spinner-border me-1" role="status"></div>
                                                    <span className="">Uploading...</span>
                                                </ShowThen>
                                                <ShowThen when={!isDocumentLoading}>
                                                    <i className="bi bi-cloud-arrow-up-fill me-2"></i>
                                                    {store.guardianshipFileName ? 'Replace File' : 'Upload File'}
                                                </ShowThen>
                                            </label>
                                            <span>{`Max file size ${AppSettings.maxUploadFileSize / 1000000}MB.`}</span>
                                            <input type="file" name="guardianshipFile" id="guardianshipFile"
                                                disabled={isLoading}
                                                key={store.guardianshipFileName || " "}
                                                onChange={(e) => {
                                                    setFieldsTouched("guardianshipFile");
                                                    handleGuardianshipUploadValueChange(e);
                                                }}
                                                className="d-none"
                                                accept={AppSettings.validUploadFileFormats.toString()}>
                                            </input>
                                        </div>
                                        <ShowThen when={hasGuardianshipFile}>
                                            <div>
                                                <span className="me-1">File Uploaded:</span>
                                                <label id="guardianship-file-show" htmlFor="guardianshipFileShow">
                                                    <a className="bds-anchor">{store.guardianshipFileName}</a>
                                                </label>
                                                <input type="file" name="guardianshipFile" id="guardianshipFile"
                                                    disabled={isLoading}
                                                    key={store.guardianshipFileName || " "}
                                                    onChange={(e) => {
                                                        setFieldsTouched("guardianshipFile");
                                                        handleGuardianshipUploadValueChange(e);
                                                    }}
                                                    className="d-none"
                                                    accept={AppSettings.validUploadFileFormats.toString()}>
                                                </input>
                                                <i className="bi bi-trash ms-1" onClick={handleRemoveGuardianshipFile}></i>
                                            </div>
                                        </ShowThen>
                                    </div>
                                </div>
                            </ShowThen>
                        </div>
                    </Card>
                </div>
                
                {/* PLACEMENT INFORMATION */}
                <div className="mt-4">
                    <Card>
                        <div className="bds-section-heading mandatory">Placement information</div>
                        <div className="row mt-3">
                            <RadioButtonGroup
                                labelText="Have previous placements been made by State agencies
                                    (may include DCS, DOE, CMHI, probation, or other funding sources)?"
                                radioGroupOptions={YesNoRadioOptions}
                                name="hasPlacement"
                                selectedValue={store.hasPlacement}
                                onChange={(event) => {
                                    store.setHasPlacement(Number(event.target.value))
                                    setFieldsTouched("hasPlacement", "placementAgencyDate", "placementAgency")
                                    handleValueChange(event, 
                                        "hasPlacement", 
                                        store.hasPlacement, 
                                        savedState.hasPlacement
                                    )
                                }}
                            ></RadioButtonGroup>
                            <ShowThen when={store.hasPlacement == enums.YesNoNARadioButtonGroupOptions.Yes}>
                                <div className="col-12 col-md-6">
                                    <label className="form-check-label" htmlFor="placement-agency-date">Please indicate date of placement agency designation:</label>
                                    <input type="text" id="placement-agency-date" name="placementAgencyDate" value={store.placementAgencyDate}
                                        onChange={(event) => { store.setPlacementAgencyDate(event.target.value); }}
                                        onBlur={(event) => {
                                            setFieldsTouched("placementAgencyDate");
                                            handleValueChange(event,
                                            "placementAgencyDate",
                                            store.placementAgencyDate,
                                            savedState.placementAgencyDate);
                                        }}
                                        className="form-control" maxLength={100} />
                                </div>
                                <div className="col-12 col-md-6">
                                    <label className="form-check-label" htmlFor="placement-agency">Name of Placement Agency:</label>
                                    <input type="text" id="placement-agency" name="placementAgency" value={store.placementAgency}
                                        onChange={(event) => { store.setPlacementAgency(event.target.value); }}
                                        onBlur={(event) => {
                                            setFieldsTouched("placementAgency");
                                            handleValueChange(event,
                                            "placementAgency",
                                            store.placementAgency,
                                            savedState.placementAgency);
                                        }}
                                        className="form-control" maxLength={100} />
                                </div>
                            </ShowThen>
                        </div>
                    </Card>
                </div>
                
                {/* GROUP HOME INFORMATION */}
                <ShowThen when={!store.isMinor}>
                    <div className="mt-4">
                        <Card>
                            <div className="bds-section-heading mandatory">Group Home Information</div>
                            <div className="row mt-3">
                                <RadioButtonGroup
                                    labelText="Has SGL (Group Home) placement been explored?"
                                    radioGroupOptions={YesNoRadioOptions}
                                    name="placementConsidered"
                                    selectedValue={store.placementConsidered}
                                    onChange={(event) => {
                                        store.setPlacementConsidered(Number(event.target.value))
                                        setFieldsTouched("placementConsidered", "placementNotesYes", "placementNotesNo")
                                        handleValueChange(event, 
                                            "placementConsidered", 
                                            store.placementConsidered, 
                                            savedState.placementConsidered
                                        )
                                    }}
                                ></RadioButtonGroup>
                                <ShowThen when={store.placementConsidered == enums.YesNoNARadioButtonGroupOptions.Yes}>
                                    <NbarTextArea
                                        id="placement-notes-yes" 
                                        name="placementNotesYes" 
                                        value={store.placementNotesYes}
                                        onChange={(event) => { 
                                            store.setPlacementNotesYes(event.target.value);
                                        }}
                                        onBlur={(event) => {
                                            setFieldsTouched("placementNotesYes");
                                            handleValueChange(event,
                                                "placementNotesYes",
                                                store.placementNotesYes,
                                                savedState.placementNotesYes
                                            )
                                        }}
                                        labelText={store.placementConsidered == enums.YesNoNARadioButtonGroupOptions.Yes ? "Description of Outcome" : "Reason for Not Exploring"}
                                    ></NbarTextArea>
                                </ShowThen>
                                <ShowThen when={store.placementConsidered == enums.YesNoNARadioButtonGroupOptions.No}>
                                    <NbarTextArea
                                        id="placement-notes-no" 
                                        name="placementNotesNo" 
                                        value={store.placementNotesNo}
                                        onChange={(event) => { 
                                            store.setPlacementNotesNo(event.target.value);
                                        }}
                                        onBlur={(event) => {
                                            setFieldsTouched("placementNotesNo");
                                            handleValueChange(event,
                                                "placementNotesNo",
                                                store.placementNotesNo,
                                                savedState.placementNotesNo
                                            )
                                        }}
                                        labelText={store.placementConsidered == enums.YesNoNARadioButtonGroupOptions.Yes ? "Description of Outcome" : "Reason for Not Exploring"}
                                    ></NbarTextArea>
                                </ShowThen>
                            </div>
                        </Card>
                    </div>
                </ShowThen>
                
                {/* DDRS INFORMATION */}
                <div className="mt-4">
                    <Card>
                        <div className="bds-section-heading mandatory">DDRS Information</div>
                        <div className="row mt-3">
                            <RadioButtonGroup
                                labelText="Does the individual currently receive services from DDRS?
                                (FSW, A&amp;D waiver, SGL)"
                                radioGroupOptions={YesNoRadioOptions}
                                name="hasCurrentDDRS"
                                selectedValue={store.hasCurrentDDRS}
                                onChange={(event) => {
                                    store.setHasCurrentDDRS(Number(event.target.value))
                                    setFieldsTouched("hasCurrentDDRS", "ddrsSelectedOptions", "ddrsOtherText")
                                    handleValueChange(event, 
                                        "hasCurrentDDRS", 
                                        store.hasCurrentDDRS, 
                                        savedState.hasCurrentDDRS
                                    )
                                    checkClearAllWaivers(event.target.value)
                                }}
                            ></RadioButtonGroup>
                          
                            <ShowThen when={store.hasCurrentDDRS == enums.YesNoNARadioButtonGroupOptions.Yes}>
                                <div className="row mt-3">
                                    <div className="col-6">
                                        <label className="form-check-label" htmlFor="ddrs-options">
                                            Please Indicate Current Services:
                                        </label>
                                        <BdsMultiSelect name="ddrsOptions"
                                            value={store.ddrsSelectedOptions}
                                            onChange={(event) => {
                                                setFieldsTouched("ddrsSelectedOptions", "ddrsOtherText");
                                                store.setDdrsSelectedOptions(event);
                                                handleUpdateWaiverOptions(event)
                                                }
                                            }
                                            onBlur={(event) => {
                                                setFieldsTouched("ddrsSelectedOptions", "ddrsOtherText");
                                                }}
                                            isClearable={true} isDisabled={false} isSearchable={true}
                                            className={""}
                                            loadingMessage={() => { return <>Loading...</> }}
                                            options={store.waiverOptions}></BdsMultiSelect>
                                    </div>
                                </div>
                            </ShowThen>

                            <ShowThen when={store.ddrsSelectedOptions?.find(o => o.label?.toLowerCase() == 'other')}>
                                <div className="row mt-3">
                                    <div className="col-6">
                                        <label className="form-check-label" htmlFor="ddrs-other-text">Please indicate other services:</label>
                                        <input type="text" id="ddrs-other-text" name="ddrsOtherText" value={store.ddrsOtherText}
                                            onBlur={(event) => {
                                                setFieldsTouched("ddrsOtherText");
                                                handleValueChange(event,
                                                "ddrsOtherText",
                                                store.ddrsOtherText,
                                                savedState.ddrsOtherText);
                                                handleAddOtherWaiverOption(event);
                                            }}
                                            onChange={(event) => { store.setDdrsOtherText(event.target.value); }}
                                            className="bds-form-text form-control" maxLength={100} />
                                    </div>
                                </div>
                            </ShowThen>                          
                        </div>
                    </Card>
                </div>

                {/* SUPPORT INFORMATION */}
                <div className="mt-4">
                    <Card>
                        <div className="bds-section-heading mandatory">Support Information</div>

                        <div className="row mt-3">
                            <NbarTextArea
                                id="natural-support-available" 
                                name="naturalSupportAvailable" 
                                value={store.naturalSupportAvailable}
                                onChange={(event) => { 
                                    store.setNaturalSupportAvailable(event.target.value);
                                }}
                                onBlur={(event) => {
                                    setFieldsTouched("naturalSupportAvailable");
                                    handleValueChange(event,
                                        "naturalSupportAvailable",
                                        store.naturalSupportAvailable,
                                        savedState.naturalSupportAvailable
                                    )
                                }}
                                labelText="What natural and informal supports are available?"
                            ></NbarTextArea>

                            <NbarTextArea
                                id="natural-support-comments" 
                                name="naturalSupportComments" 
                                value={store.naturalSupportComments}
                                onChange={(event) => { 
                                    store.setNaturalSupportComments(event.target.value);
                                }}
                                onBlur={(event) => {
                                    setFieldsTouched("naturalSupportComments");
                                    handleValueChange(event,
                                        "naturalSupportComments",
                                        store.naturalSupportComments,
                                        savedState.naturalSupportComments
                                    )
                                }}
                                labelText="How are these supports utilized?"
                            ></NbarTextArea>
                        </div>

                        <div className="row mt-3">
                            <RadioButtonGroup
                                labelText="Have Community Supports been explored?"
                                radioGroupOptions={YesNoRadioOptions}
                                name="hasCommunitySupportExplored"
                                selectedValue={store.hasCommunitySupportExplored}
                                onChange={(event) => {
                                    store.setHasCommunitySupportExplored(Number(event.target.value))
                                    setFieldsTouched("hasCommunitySupportExplored", "communitySupportComments")
                                    handleValueChange(event, 
                                        "hasCommunitySupportExplored", 
                                        store.hasCommunitySupportExplored, 
                                        savedState.hasCommunitySupportExplored
                                    )
                                }}
                            ></RadioButtonGroup>
                            <ShowThen when={store.hasCommunitySupportExplored == enums.YesNoNARadioButtonGroupOptions.Yes}>
                                <NbarTextArea
                                    id="community-support-comments" 
                                    name="communitySupportComments" 
                                    value={store.communitySupportComments}
                                    onChange={(event) => { 
                                        store.setCommunitySupportComments(event.target.value);
                                    }}
                                    onBlur={(event) => {
                                        setFieldsTouched("communitySupportComments");
                                        handleValueChange(event,
                                            "communitySupportComments",
                                            store.communitySupportComments,
                                            savedState.communitySupportComments
                                        )
                                    }}
                                    labelText="If so, which ones and how are they being used?"
                                ></NbarTextArea>
                            </ShowThen>
                        </div>
                    </Card>
                </div>

                {/* CAREGIVER INFORMATION */}
                <div className="mt-4">
                    <Card>
                        <div className="bds-section-heading mandatory">Caregiver Information</div>

                        <div className="row mt-3">
                            <RadioButtonGroup
                                labelText="Has there been a loss of a primary caregiver?"
                                radioGroupOptions={YesNoRadioOptions}
                                name="hasLostPrimaryCaregiver"
                                selectedValue={store.hasLostPrimaryCaregiver}
                                onChange={(event) => {
                                    store.setHasLostPrimaryCaregiver(Number(event.target.value))
                                    setFieldsTouched("hasLostPrimaryCaregiver", 
                                        "dateOfPrimaryCaregiverLoss", 
                                        "primaryCaregiverCapacity", 
                                        "currentArragementOrServices", 
                                        "primaryCaregiverOverEighty"
                                    )
                                    handleValueChange(event, 
                                        "hasLostPrimaryCaregiver", 
                                        store.hasLostPrimaryCaregiver, 
                                        savedState.hasLostPrimaryCaregiver
                                    )
                                }}
                            ></RadioButtonGroup>

                            <ShowThen when={store.hasLostPrimaryCaregiver == enums.YesNoNARadioButtonGroupOptions.Yes}>
                                <div className="col-12">
                                    <label className="form-check-label" htmlFor="date-of-loss">When?</label>
                                    <BdsDatePicker
                                        id="date-of-primary-caregiver-loss"
                                        name="dateOfPrimaryCaregiverLoss" 
                                        value={store.dateOfPrimaryCaregiverLoss}
                                        onChange={(event) => { 
                                            store.setDateOfPrimaryCaregiverLoss(event.target.value); 
                                        }}
                                        onBlur={(event) => {
                                            setFieldsTouched("dateOfPrimaryCaregiverLoss");
                                            if (Utility.isHtmlDateStringValid(event.target.value)) {
                                                handleValueChange(event,
                                                "dateOfPrimaryCaregiverLoss",
                                                store.dateOfPrimaryCaregiverLoss,
                                                savedState.dateOfPrimaryCaregiverLoss)
                                            }
                                        }}
                                        cssClass="bds-form-text bds-section-dob form-control"
                                    ></BdsDatePicker>                                     
                                </div>

                                <NbarTextArea
                                    id="current-primary-caregiver-capacity-description" 
                                    name="primaryCaregiverCapacityDescription" 
                                    value={store.primaryCaregiverCapacityDescription}
                                    onChange={(event) => { 
                                        store.setPrimaryCaregiverCapacityDescription(event.target.value);
                                    }}
                                    onBlur={(event) => {
                                        setFieldsTouched("primaryCaregiverCapacityDescription");
                                        handleValueChange(event,
                                            "primaryCaregiverCapacityDescription",
                                            store.primaryCaregiverCapacityDescription,
                                            savedState.primaryCaregiverCapacityDescription
                                        )
                                    }}
                                    labelText="In what capacity were they primary?"
                                ></NbarTextArea>

                                <NbarTextArea
                                    id="current-caregiver-arrangement" 
                                    name="currentCaregiverArrangementDescription" 
                                    value={store.currentCaregiverArrangementDescription}
                                    onChange={(event) => { 
                                        store.setCurrentCaregiverArrangementDescription(event.target.value);
                                    }}
                                    onBlur={(event) => {
                                        setFieldsTouched("currentCaregiverArrangementDescription");
                                        handleValueChange(event,
                                            "currentCaregiverArrangementDescription",
                                            store.currentCaregiverArrangementDescription,
                                            savedState.currentCaregiverArrangementDescription
                                        )
                                    }}
                                    labelText="What is the current arrangement/services?"
                                ></NbarTextArea>
                            </ShowThen>

                            <ShowThen when={store.hasLostPrimaryCaregiver == enums.YesNoNARadioButtonGroupOptions.No}>
                                <RadioButtonGroup
                                    labelText="Is the primary caregiver over 80?"
                                    radioGroupOptions={YesNoRadioOptions}
                                    name="primaryCaregiverOverEighty"
                                    selectedValue={store.primaryCaregiverOverEighty}
                                    onChange={(event) => {
                                        store.setPrimaryCaregiverOverEighty(Number(event.target.value))
                                        setFieldsTouched("primaryCaregiverOverEighty")
                                        handleValueChange(event, 
                                            "primaryCaregiverOverEighty", 
                                            store.primaryCaregiverOverEighty, 
                                            savedState.primaryCaregiverOverEighty
                                        )
                                    }}
                                ></RadioButtonGroup>
                            </ShowThen>
                        </div>

                        <div className="row mt-3">
                            <RadioButtonGroup
                                labelText="Is there a secondary caregiver?"
                                radioGroupOptions={YesNoRadioOptions}
                                name="hasSecondaryCaregiver"
                                selectedValue={store.hasSecondaryCaregiver}
                                onChange={(event) => {
                                    store.setHasSecondaryCaregiver(Number(event.target.value))
                                    setFieldsTouched("hasSecondaryCaregiver", "secondaryCaregiverLessThanEighty")
                                    handleValueChange(event, 
                                        "hasSecondaryCaregiver", 
                                        store.hasSecondaryCaregiver, 
                                        savedState.hasSecondaryCaregiver
                                    )
                                }}
                            ></RadioButtonGroup>

                            <ShowThen when={store.hasSecondaryCaregiver == enums.YesNoNARadioButtonGroupOptions.Yes}>
                                <RadioButtonGroup
                                    labelText="Is the secondary caregiver less than 80 years old?"
                                    radioGroupOptions={YesNoRadioOptions}
                                    name="secondaryCaregiverLessThanEighty"
                                    selectedValue={store.secondaryCaregiverLessThanEighty}
                                    onChange={(event) => {
                                        store.setSecondaryCaregiverLessThanEighty(Number(event.target.value))
                                        setFieldsTouched("secondaryCaregiverLessThanEighty")
                                        handleValueChange(event, 
                                            "secondaryCaregiverLessThanEighty", 
                                            store.secondaryCaregiverLessThanEighty, 
                                            savedState.secondaryCaregiverLessThanEighty
                                        )
                                    }}
                                ></RadioButtonGroup>
                            </ShowThen>
                        </div>
                    </Card>
                </div>

                {/* SAFETY INFORMATION */}
                <div className="mt-4">
                    <Card>
                        <div className="bds-section-heading mandatory">Safety Information</div>
                        <div className="row mt-3">
                            <RadioButtonGroup
                                labelText="Has there been abuse or neglect substantiated by BDS, ISDH, APS, DCS, law enforcement?"
                                radioGroupOptions={YesNoRadioOptions}
                                name="abuseNeglectSubstantiated"
                                selectedValue={store.abuseNeglectSubstantiated}
                                onChange={(event) => {
                                    store.setAbuseNeglectSubstantiated(Number(event.target.value))
                                    setFieldsTouched("abuseNeglectSubstantiated", "abuseNeglectDescription")
                                    handleValueChange(event, 
                                        "abuseNeglectSubstantiated", 
                                        store.abuseNeglectSubstantiated, 
                                        savedState.abuseNeglectSubstantiated
                                    )
                                }}
                            ></RadioButtonGroup>
                            <ShowThen when={store.abuseNeglectSubstantiated == enums.YesNoNARadioButtonGroupOptions.Yes}>
                                <NbarTextArea
                                    id="abuse-neglect-description" 
                                    name="abuseNeglectDescription" 
                                    value={store.abuseNeglectDescription}
                                    onChange={(event) => { 
                                        store.setAbuseNeglectDescription(event.target.value);
                                    }}
                                    onBlur={(event) => {
                                        setFieldsTouched("abuseNeglectDescription");
                                        handleValueChange(event,
                                            "abuseNeglectDescription",
                                            store.abuseNeglectDescription,
                                            savedState.abuseNeglectDescription
                                        )
                                    }}
                                    labelText="Who was the perpetrator of the abuse/neglect? Family member, community member, other? Please list dates and description of incident."
                                ></NbarTextArea>
                            </ShowThen>
                        </div>

                        <div className="row mt-3">
                            <RadioButtonGroup
                                labelText="Does the individual have an extraordinary safety risk?"
                                radioGroupOptions={YesNoRadioOptions}
                                name="hasExtraordinarySafetyRisk"
                                selectedValue={store.hasExtraordinarySafetyRisk}
                                onChange={(event) => {
                                    store.setHasExtraordinarySafetyRisk(Number(event.target.value))
                                    setFieldsTouched("hasExtraordinarySafetyRisk", "extraordinarySafetyRiskDescription")
                                    handleValueChange(event, 
                                        "hasExtraordinarySafetyRisk", 
                                        store.hasExtraordinarySafetyRisk, 
                                        savedState.hasExtraordinarySafetyRisk
                                    )
                                }}
                            ></RadioButtonGroup>                         
                            <ShowThen when={store.hasExtraordinarySafetyRisk == enums.YesNoNARadioButtonGroupOptions.Yes}>
                                <NbarTextArea
                                    id="safety-risk-description" 
                                    name="extraordinarySafetyRiskDescription" 
                                    value={store.extraordinarySafetyRiskDescription}
                                    onChange={(event) => { 
                                        store.setExtraordinarySafetyRiskDescription(event.target.value);
                                    }}
                                    onBlur={(event) => {
                                        setFieldsTouched("extraordinarySafetyRiskDescription");
                                        handleValueChange(event,
                                            "extraordinarySafetyRiskDescription",
                                            store.extraordinarySafetyRiskDescription,
                                            savedState.extraordinarySafetyRiskDescription
                                        )
                                    }}
                                    labelText="Please explain:"
                                ></NbarTextArea>
                            </ShowThen>
                        </div>
                    </Card>
                </div>

                {/* NAVIGATION CONTROLS */}
                <div className="row mt-4">
                    <div className="col-12 col-md-6 order-1 order-md-0 mt-3 mt-md-0">
                        <button type="button" className="btn bds-btn-primary" disabled={isLoading}
                            onClick={() => NavigationHelper.gotoNbarStartApplication(navigate, nbarApplicationRefId)}>Back</button>
                        <IsAuthenticated>
                            <button type="button" className="btn bds-btn-primary ms-0 ms-md-3 mt-3 mt-md-0"
                                onClick={() => NavigationHelper.gotoDashboard(navigate)}>Save for Later</button>
                        </IsAuthenticated>
                    </div>
                    <div className="col-12 col-md-6 order-0 order-md-1 text-start text-md-end">
                        <button type="button" disabled={isLoading} onClick={() => handleGoToNextStep()}
                            className="btn bds-btn-primary">Next</button>
                    </div>
                </div>
            </form>
        </>
    )
}

export default observer(DemographicInformation)