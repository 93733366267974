import { useEffect, useRef, useState } from "react";
import { AppSettings } from "../../../app-setting";
import { Card } from "../../../shared-module/components/card/card.component";
import { AccessIndianaCard } from "../access-indiana/access-indiana-card/access-indiana-card.component";
import * as bootstrap from "bootstrap";
import { NavigationHelper } from "../../../shared-module/helpers/navigation-helper";
import { useNavigate } from "react-router-dom";
import { ShowThen } from "../../../shared-module/components/show-then/show-then.component";
import $ from "jquery";
import "./../../../assets/scripts/print-this";
import { GatewayOapApi } from "../../../shared-module/models/gateway-oap-api.models";
import { Utility } from "../../../shared-module/helpers/utility";
import { AppStateService } from "../../services/app-state-service";
import { ApiConfig } from "shared-module/services/api-config";
import { SessionService } from "shared-module/services/session.service";
import BdsCheckbox from "shared-module/components/checkbox/bds-checkbox.component";

function HomePage() {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<GatewayOapApi.UserModel>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const tooltipRef = useRef();
  const [confirmed, setConfirmed] = useState(false);

  function handleConfirmation(): void {
    setConfirmed(!confirmed);
  };

  useEffect(() => {
    bindPrintButton();

    const tooltip = bootstrap.Tooltip.getOrCreateInstance(tooltipRef.current, {
      boundary: document.body,
      container: '[data-bs-toggle="tooltip"]',
      trigger: 'hover'
    });

    setIsLoading(true);
    Utility.showLoadingOverlay();

    Promise.all([
      SessionService.isAuthenticated(),
      SessionService.getProfile()
    ])
      .then((response) => {
        setIsAuthenticated(response[0]);
        setUser(response[1]);
        return Promise.resolve(true);
      })
      .finally(() => {
        setIsLoading(false);
        Utility.hideLoadingOverlay();
      });

    return () => {
      tooltip.dispose();
    }

  }, []);

  function handleLogout() {
    NavigationHelper.gotoUnauthorization(navigate);
  }

  function handleStartApplication() {
    if (confirmed) {
      AppStateService.setShowGuestApplicationDisclaimer(true);
      NavigationHelper.gotoStartApplication(navigate)
    }
  }

  function bindPrintButton() {
    $("#print-instructions").on('click', function () {
      ($("#instructions") as any).printThis({
        debug: false,
        importCSS: false,
        header: "<h3>" + AppSettings.bdsAcronym + " Gateway Instructions</h3>"
      });
      return false;

    });
  }

  return (
    <>
      <div className="row mt-4">
        <div className="col-12 col-md-8 order-2 order-md-0">
          <ShowThen when={!isLoading && isAuthenticated}>
            <button type="button" onClick={() => handleLogout()}
              className="btn bds-btn-primary">Logout</button>
          </ShowThen>
        </div>
        <div className="col-12 col-md-2 text-start text-md-center order-1 order-md-1 mt-2 mt-md-0">
        </div>
        <div className="col-12 col-md-2 text-start text-md-end order-0 order-md-2">
          <ShowThen when={!isLoading && isAuthenticated}>
            <button type="button"
              onClick={() => NavigationHelper.gotoDashboard(navigate)}
              className="btn bds-btn-primary">My Dashboard</button>
          </ShowThen>
        </div>
      </div>

      <div id="instructions" className="mt-4">
        <style>
          {"@media print { .no-print, .no-print * { display: none !important; } } body { font-size: 14px; }"}
        </style>
        <Card>
          <ShowThen when={!isLoading && isAuthenticated}>
            <div className="row mb-4">
              <span className="bds-welcome-heading">Welcome, { user && (user.firstName + " " + user.lastName) }</span>
            </div>
          </ShowThen>
          <div>
            <p>
              Welcome to the online application for services available through the
              {" "}{AppSettings.bdsName} ({AppSettings.bdsAcronym}).
	            This application is used to apply for waiver services and/or group home
              placement. If you are currently receiving {" "}{AppSettings.bdsAcronym}{" "}
              services, please contact your local {" "}{AppSettings.bdsAcronym}{" "}
              district office. If you would like to review additional training and 
              instructions about this application, as well as other {" "}{AppSettings.bdsAcronym}{" "}
              information please {" "}
                <a className="bds-anchor" 
                  href={AppSettings.Link_DDRSLanding}
                  target="_blank" >click here</a>.
            </p>
            <p>
            Please note, unless you apply using Access Indiana, you will not be able to save your progress and finish at a later time. 
            If you stop working on your application for more than 15 minutes, then the system will timeout and you will need to start a new application. 
            All information must be completed once you begin the application. 
            Please ensure you have all of the required information prior to beginning the application.
            </p>
            <div className="no-print">
              <ShowThen when={!isLoading && !isAuthenticated}>
                <AccessIndianaCard></AccessIndianaCard>
              </ShowThen>
            </div>
            <p>
              To complete the online application the following information is
              required:
            </p>
            <ul className="bds-item-list">
              <li>The applicant's name</li>
              <li>Social security number</li>
              <li>Date of birth</li>
              <li>Applicant's current physical address</li>
              <li>
                Current physical address and mailing address if it is different from the current physical address.
              </li>
              <li>Applicant's contact information such as phone and/or email</li>
              <li>
                The age the applicant was diagnosed with a developmental or
                intellectual disability
              </li>
              <li>
                Brief description of how the disability affects applicant's daily
                life
              </li>
            </ul>
            <p>
              If the applicant is a minor or is an adult that has someone who has
              been legally designated to help make decisions with/for them the
              following information will be required:
            </p>
            <ul className="bds-item-list">
              <li>Name of legal guardian or legal representative</li>
              <li>Relationship to applicant</li>
              <li>Address of legal guardian or legal representative</li>
              <li>
                Contact information such as phone and/or email of legal guardian
                or legal representative
              </li>
            </ul>
            <p>
              {AppSettings.bdsAcronym}{" "} will also gather some additional
              information that you are not required to answer, however, your
              answers to these questions can help to improve our services and
              supports. We do not use your answers to discriminate or to make
              decisions about your eligibility or access to services. The
              following information will be asked about the applicant:
            </p>

            <ul className="bds-item-list">
              <li>
                If applicant currently has Medicaid and the number (You are not
                required to have Medicaid in place to apply for
                {" "}{AppSettings.bdsAcronym}{" "} services.)
              </li>
              <li>Gender</li>
              <li>Marital Status</li>
              <li>Education information</li>
              <li>Race/Ethnicity information</li>
              <li>Preferred Language</li>
              <li>
                If the applicant has ever been assessed by Vocational
                Rehabilitation
              </li>
            </ul>
            <p>
              The individual and/or legal guardian/representative will be asked to
              check a signature box which will serve as signature to pursue
              {" "}{AppSettings.bdsAcronym}{" "} services. At any time, you can choose to
              decline services or stop the application process.
            </p>
            <p>
              Upon completion, please take a moment to review all answers for
              accuracy prior to submission. Upon submission, you will have the
              opportunity at that time to download a copy of the submitted
              application for your records. Once submitted you will receive a
              message that the application was successfully submitted along with{" "}
              <a className="bds-anchor"
                href={AppSettings.Link_DDRSForms}
                target="_blank"
              >
                links to application supporting documents
              </a>{" "}
              and information on what to expect next.
            </p>
            <p>
              If at any time you need assistance you may contact your
              {" "}{AppSettings.bdsAcronym}{" "} district office. To find your district
              office{" "}
              <a className="bds-anchor"
                href={AppSettings.Link_DistrictOffices}
                target="_blank"
              >
                go here
              </a>
              .
            </p>
          </div>
        </Card>
      </div>

      <div className="mt-4">
        <Card>
          <div className="row">
            <div className="col-12">
              <BdsCheckbox 
                id="confirmInstructions"
                name="confirmInstructions"
                checked={confirmed} 
                onChange={handleConfirmation}
                label="By checking the box, you are confirming that you have read the instructions."
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12 col-md-6 order-1 order-md-0 mt-3 mt-md-0">
              <button type="button" id="print-instructions" className="btn bds-btn-primary">
                Print the Instructions
              </button>
            </div>
            <div className="col-12 col-md-6 order-0 order-md-1 text-start text-md-end">
              <span data-bs-toggle="tooltip" ref={tooltipRef} data-bs-offset="0,15"
                data-bs-title="Please signify that you have read the instructions before continuing.">
                <button type="button" disabled={!confirmed}
                  onClick={() => handleStartApplication()}
                  id="start-application" className="btn bds-btn-primary">
                    <ShowThen when={isAuthenticated}>
                      Start a New Application
                    </ShowThen>
                    <ShowThen when={!isAuthenticated}>
                      Start Guest Application
                    </ShowThen>
                </button>
              </span>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

export default HomePage;
