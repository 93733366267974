import { GatewayOapApi } from "../../models/gateway-oap-api.models";
import { ApiBaseResource, IApiConfig } from "../api.base.resource";

export class NbarTreatmentResource extends ApiBaseResource {
  constructor(apiConfig: IApiConfig) {
    super(apiConfig);
  }

    public getNbarTreatmentInformation(treatmentInformationId: number, abortController?: AbortController): Promise<GatewayOapApi.ApiSingleResponseOfTreatmentInformationModel> {
        return super.getRequest(`api/v1/nbar/treatment/${treatmentInformationId}`, null, abortController) as
            Promise<GatewayOapApi.ApiSingleResponseOfTreatmentInformationModel>;
    }

    public saveNbarTreatmentInformation(
        treatmentInformationId: number,
        treatmentDescription: string,
        hasPrimaryCarePhysician: number,
        physicianName: string,
        medicalDiagnosisByPhysician: string,
        hasPrescribedMedications: number,
        hasPsychiatrist: number,
        psychiatristName: string,
        medicalDiagnosisByPsychiatrist: string,
        hasRefusalToTakeMedications: number,
        refusalDescription: string,
        isReceivingBehaviorManagementServices: number,
        hasRecievedBehaviorManagementConsultation: number,
        hasRecentTriggerEvents: number,
        triggerEventDescription: string,
        isReceivingAdditionalServices: number,
        hasHospitalization: number,
        hospitalizationDescription: string,
        hasLawEnforcement: number,
        lawEnforcementDescription: string,
        hasStructuredSchedule: number,
        scheduleDescription: string,
        hasCommunityActivities: number,
        communityActivityDescription: string,
        meaningfulDayDescription: string,
        waiverCapacityDescription: string,
        isStudent: number,
        isFullTimeStudent: number,
        isPartTimeStudent: number,
        partTimeDescription: string,
        hasSchoolBehaviors: number,
        hasIEP: number,
        behaviorFrequencyTypeId: number,
        behaviorDescription: string, 
        abortController?: AbortController)
        : Promise<GatewayOapApi.ApiIntegerResponse>
    {
      return super.postRequest(`api/v1/nbar/treatment/update`, 
      null, 
      {
        treatmentInformationId,
        treatmentDescription,
        hasPrimaryCarePhysician,
        physicianName,
        medicalDiagnosisByPhysician,
        hasPrescribedMedications,
        hasPsychiatrist,
        psychiatristName,
        medicalDiagnosisByPsychiatrist,
        hasRefusalToTakeMedications,
        refusalDescription,
        isReceivingBehaviorManagementServices,
        hasRecievedBehaviorManagementConsultation,
        hasRecentTriggerEvents,
        triggerEventDescription,
        isReceivingAdditionalServices,
        hasHospitalization,
        hospitalizationDescription,
        hasLawEnforcement,
        lawEnforcementDescription,
        hasStructuredSchedule,
        scheduleDescription,
        hasCommunityActivities,
        communityActivityDescription,
        meaningfulDayDescription,
        waiverCapacityDescription,
        isStudent,
        isFullTimeStudent,
        isPartTimeStudent,
        partTimeDescription,
        hasSchoolBehaviors,
        hasIEP,
        behaviorFrequencyTypeId,
        behaviorDescription, 
      },
      abortController) as 
        Promise<GatewayOapApi.ApiIntegerResponse>;
    }


}